<template>
  <el-container id="approval-text-tab">
    <div class="tab-pane fade" id="pills-4" role="tabpanel">
      <div v-for="(adFormat, i) in sortedAdFormat" :key="i">
        <p class="label-name" style="color: #303133; font-weight: 500; font-size: 14px">
          {{ genTextLabelName(textSets[adFormat]) }}
        </p>
        <ul class="listForm">
          <li
            v-for="(labelText, idx) in textSets[adFormat]"
            :key="`${i}_${idx}`"
            :style="{ backgroundColor: !labelText.isLatest ? 'rgba(229, 229, 229, 0.8745098039)' : '' }"
          >
            <div class="form-body">
              <div>
                <section style="display: flex" class="form-title-section">
                  <div
                    class="form-title"
                    :style="{
                      backgroundColor: backgroundColor(labelText.approvalStatus),
                      color: textColor(labelText.approvalStatus),
                      height: '30px',
                    }"
                  >
                    {{ labelText.approvalStatus }}
                  </div>
                  <el-tooltip
                    v-if="!labelText.isLatest"
                    placement="right-start"
                    effect="light"
                    popper-class="notifier__tooltip"
                    :visible-arrow="false"
                    :open-delay="500"
                    :content="NOT_ABLE_CHANGE_STATUS_MSG"
                  >
                    <span class="notifier--warning" style="align-self: center; margin-left: 15px"
                      ><i class="el-icon-warning error-mark" style=""></i
                    ></span>
                  </el-tooltip>
                  <el-button
                    v-if="
                      ['/approval-detail'].includes($route.path) &&
                      labelText.approvalStatus === AssetTextStatus.FIXING &&
                      labelText.isLatest
                    "
                    type="primary"
                    class="form-title__button"
                    @click="updateText(labelText)"
                  >
                    テキスト編集
                  </el-button>
                </section>
                <div class="form-group" style="min-height: 200px; margin-top: 10px">
                  <div class="row row-2 mb-2" v-if="labelText.labeledTexts && labelText.labeledTexts.length > 0">
                    <div class="col-3" v-for="(text, id) in labelText.labeledTexts" :key="`${id}_${i}_${idx}`">
                      <label class="form-label" :id="text.labelId">{{ text.labelName }}</label>
                      <div v-if="text.textValues.length > 0">
                        <div v-for="(textValue, index) in text.textValues" :key="`${index}_${id}_${i}_${idx}`">
                          <div :class="`textbox ${textValue.value === '' && 'empty'}`">
                            {{ textValue.value || '未入力' }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="textbox empty" style="min-height: 19px">未入力</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="thumb-name">
                  <span class="thumb-ver">v{{ labelText.version }}</span>
                </div>
                <div class="form-group mb-3">
                  <label class="form-label">
                    <i class="fas fa-comments" />
                    制作意図
                  </label>
                  <textarea
                    :class="labelText.isLatest ? 'form-control' : 'form-control text-old-version'"
                    :disabled="!labelText.isLatest"
                    :value="labelText.creatorComment"
                    @input="
                      $event =>
                        updateCreatorComment($event, labelText.textSetId, labelText.platformId, labelText.version)
                    "
                  ></textarea>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <edit-text-set-dialog
      v-if="true"
      ref="editTextSetDialog"
      :platform-id="textSetDraft.platformId || ''"
      :ad-format-id="textSetDraft.adFormatId || ''"
      :ad-format-name="textSetDraft.adFormatName || ''"
      :ad-formats="adFormats"
      :ad-format="adFormat"
      :is-multiple="true"
      @get-text-sets-draft="(texts, form) => textsDraft(texts, form)"
    />
  </el-container>
</template>
<style scoped>
#approval-text-tab .empty {
  color: #cfcece;
}
#approval-text-tab .disabled {
  background-color: #eeeeee;
  opacity: 0.8;
  pointer-events: none;
}
#approval-text-tab {
  width: 100%;
}
#approval-text-tab .tab-pane {
  width: inherit;
  padding: 10px 15px;
}
#approval-text-tab .text-old-version {
  background-color: #f5f7fa !important;
  cursor: not-allowed;
}
#approval-text-tab .label-name {
  width: inherit;
}
#approval-text-tab .listForm {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}
#approval-text-tab .listForm .form-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#approval-text-tab .listForm .form-group {
  margin-bottom: 1rem;
}
#approval-text-tab .listForm {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}
#approval-text-tab .listForm .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#approval-text-tab .listForm .col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: calc(25% - 2px);
}
#approval-text-tab .listForm .row.row-2 {
  margin-right: -1px;
  margin-left: -1px;
}
#approval-text-tab .listForm .row.row-2 > [class*='col-'] {
  padding-left: 1px;
  padding-right: 1px;
}
#approval-text-tab .listForm .mb-2 {
  margin-bottom: 0.5rem !important;
}
#approval-text-tab .listForm li {
  font-size: 12px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 8px 16px;
}
#approval-text-tab .listForm .form-title {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  max-width: fit-content;
  border: 1px solid #d3d4d6 !important;
  cursor: default;
  min-width: 100px;
  border-radius: 4px;
  text-align: center;
}

#approval-text-tab .listForm .form-title__button {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  max-width: fit-content;
  border: 1px solid #eeeded;
  cursor: default;
  min-width: 100px;
  text-align: center;
  margin-left: 1vw;
}
#approval-text-tab .listForm .textbox {
  white-space: pre-wrap;
  border: 1px solid #d6d6d6;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin: 0 5px 0.2rem 0;
  cursor: default;
  word-break: break-all;
}
#approval-text-tab .listForm .form-label {
  margin-bottom: 5px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  color: #060630;
}
#approval-text-tab .listForm li .thumb-name {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  color: #4692b5;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 10px;
}
#approval-text-tab .listForm li .thumb-name .thumb-ver {
  display: inline-block;
}
/* #approval-text-tab .listForm li .thumb-name .thumb-ver::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #4692b5;
  margin-right: 8px;
  border-radius: 50%;
} */
#approval-text-tab .listForm textarea {
  flex: 1;
}
#approval-text-tab .listForm textarea.form-control {
  min-height: 70px;
}
#approval-text-tab .listForm textarea.form-control {
  height: auto;
}
#approval-text-tab .listForm .form-control {
  display: block;
  padding: 8px 10px;
  width: calc(100% - 20px);
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 12px;
  color: #060630;
  font-weight: 500;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#approval-text-tab .listForm textarea {
  overflow: auto;
  resize: vertical;
}
#approval-text-tab .listForm input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
</style>
<script>
import _ from 'lodash'
import EditTextSetDialog from '../ad-format-tab/components/edit-text-set-dialog.vue'
import { AssetTextStatus } from '@/mixins/assetTextStatus'
import { NOT_ABLE_CHANGE_STATUS_MSG } from '@/mixins/constant'

export default {
  props: {
    textSets: { type: Object, default: () => {} },
    adFormats: { type: Array, default: () => [] },
  },
  data() {
    return {
      textSetsOriginals: {},
      textSetDraft: {},
      adFormat: {},
      NOT_ABLE_CHANGE_STATUS_MSG: NOT_ABLE_CHANGE_STATUS_MSG,
    }
  },
  components: { EditTextSetDialog },
  created() {
    window.ApprovalTextTab = this
  },
  methods: {
    log(a, b) {
      console.log(a, b)
    },
    updateCreatorComment(e, textSetId, platformId, version) {
      this.$emit('change-creator-comment', e.target.value, textSetId, platformId, version)
    },
    genTextLabelName(data) {
      return `${data[0].platformId} | ${data[0].adFormatName}`
    },
    omitEmptyText(textValues) {
      return textValues.filter(textValue => !_.isEmpty(textValue))
    },
    updateText(updatedTextSet) {
      this.textSetDraft = updatedTextSet
      this.adFormat = this.adFormats.find(adFormat => adFormat.id === updatedTextSet.adFormatId)
      const textDraft = _.assign({}, updatedTextSet, {
        labeledTexts:
          updatedTextSet.labeledTexts &&
          updatedTextSet.labeledTexts.map(labeledText => {
            const newTextValues = labeledText.textValues.map(text => text.value)
            return _.assign({}, labeledText, {
              textValues: labeledText.textValues && this.omitEmptyText(newTextValues),
            })
          }),
      })
      this.$refs.editTextSetDialog.show(textDraft)
    },
    // set backGround Color for approval status assetGroup
    backgroundColor(status) {
      switch (status) {
        case '監修中':
          return '#909399'
        case '要修正':
          return '#ef342f'
        case '承認':
          return 'rgb(91, 184, 93)'
        case '破棄':
          return '#f4f4f5'
        default:
          return 'white'
      }
    },
    // set text Color for approval status assetGroup
    textColor(status) {
      switch (status) {
        case '破棄':
          return '#06063096'
        case '監修未提出':
          return 'rgba(6, 6, 48, 0.45)'
        default:
          return 'white'
      }
    },
    textsDraft(draft, form) {
      const isTextSetEqual = this.compareTextSet(draft)
      // draft.version - 1 to get old version after update
      this.$emit('update-text', draft, form, isTextSetEqual, draft.version - 1)
    },
    compareTextSet(textSet) {
      const originalTextSet = this.textSetsOriginals[textSet.adFormatId]?.find(
        // textSet.version - 1 to get old version after update
        originTextSet => originTextSet.textSetId === textSet.textSetId && originTextSet.version === textSet.version - 1
      )
      const result = textSet.labeledTexts.map((text, i) => {
        return (
          !_.isUndefined(originalTextSet.labeledTexts[i]) &&
          _.isEqual(
            text.textValues,
            originalTextSet.labeledTexts[i].textValues.flatMap(text => text.value)
          )
        )
      })
      return !_.includes(result, false)
    },
  },
  computed: {
    AssetTextStatus() {
      return AssetTextStatus
    },
    sortedAdFormat() {
      const sorted = _.fromPairs(
        _.chain(this.textSets)
          .toPairs()
          .sortBy([
            ([, value]) => value[0].platformName.trim().toLowerCase(),
            ([, value]) => value[0].adFormatName.trim().toLowerCase(),
          ])
          .value()
      )
      return Object.keys(sorted)
    },
  },
  watch: {
    textSets() {
      this.textSetsOriginals = this.textSets
    },
  },
}
</script>
