<template>
  <el-container id="text-master" v-loading="loading">
    <el-col :span="24">
      <select-bar
        :selected="selected"
        :is-tabs-exists="tabs.length !== 0"
        :display-type="displayType"
        :is-search-open="isDrawerShow"
        target-type="textSet"
        @reload="d => getTextSets()"
        @go-to-creative-list="goToCreativeList"
        @change-selected="changeSelected"
        @open-tag-dialog="openTagDialog"
        @open-approval-request-dialog="$refs.approvalRequestDialog.show('textSet')"
        @toggle-search="toggleSearch"
        @toggle-display-type="toggleDisplayType"
        @delete-selected="deleteSelected"
        @download-selected="e => downloadFile(e)"
      />

      <el-collapse-transition>
        <drawer-text
          ref="drawer"
          v-show="isDrawerShow"
          :tags="tags"
          :selected-promotion-ids="selectedPromotionIds"
          @search-text-sets="getTextSets"
        />
      </el-collapse-transition>

      <el-main
        v-if="
          $route.path !== appRoute.CREATIVE_REGIST || ($route.path === appRoute.CREATIVE_REGIST && tabs.length !== 0)
        "
      >
        <el-tabs
          v-model="activeTab"
          type="border-card"
          @tab-remove="name => removeTab(name, true)"
          @tab-click="clickTab"
        >
          <el-tab-pane v-if="$route.path !== appRoute.CREATIVE_REGIST" name="add" label="add" class="p-15px">
            <span slot="label">
              <i class="fas fa-plus-circle" />
            </span>

            <div :style="{ height: tabHeight + 'px' }">
              <div>【スタート方法】</div>
              <ul>
                <li>TEXT 新規追加: 画面右下の「テキスト新規作成」ボタン</li>
                <li>
                  広告フォーマットを指定して表示:
                  <i class="fas fa-plus-circle" /> ボタンを押して媒体･広告フォーマットを選択
                </li>
                <li>詳細検索: サイドバーの検索ボタン（検索条件無しで「検索」を押すと全件表示）</li>
              </ul>
            </div>
          </el-tab-pane>

          <el-tab-pane
            id="inner-tabs"
            v-for="(r, j) in tabs"
            :key="j"
            :name="r.platformId"
            :label="r.platformId"
            :closable="$route.path !== appRoute.CREATIVE_REGIST"
            lazy
          >
            <span slot="label">
              <i class="fas fa-home" />
              {{ r.platformId }}
            </span>

            <el-tabs
              v-model="r.activeName"
              type="border-card"
              @tab-remove="name => removeTab(name, false, r.platformId)"
            >
              <el-tab-pane
                v-for="({ name, adFormatId, platformId }, i) in r.textSets"
                :key="i"
                :name="name"
                :label="name"
                :closable="$route.path !== appRoute.CREATIVE_REGIST"
                lazy
              >
                <ad-format-tab
                  display-type="default"
                  :isSmallTag="true"
                  :isShowVersionAndStatus="true"
                  :selected="selected"
                  :selected-promotion-ids="selectedPromotionIds"
                  :platform-id="platformId"
                  :ad-format-id="adFormatId"
                  :ad-format-name="name"
                  :ad-formats="adFormats"
                  :text-sets="groupByTextSets[adFormatId]"
                  :height="tabHeight"
                  @change-selected-at-tab="changeSelectedAtTab"
                  @get-text-sets="getTextSets"
                  @change-creator-comment="changeCreatorComment"
                />
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
        </el-tabs>
        <el-button
          v-if="![appRoute.CREATIVE_REGIST].includes($route.path)"
          @click="$refs.registTextSetDialog.show()"
          class="create-new"
        >
          <i class="far fa-file-alt" style="font-size: larger"></i>
          テキスト新規作成
        </el-button>
      </el-main>
    </el-col>

    <select-ad-format-dialog
      v-if="$route.path !== appRoute.CREATIVE_REGIST"
      ref="selectAdFormatDialog"
      :ad-formats="adFormats"
      @add-ad-format="addAdFormat"
    />

    <approval-request-dialog
      ref="approvalRequestDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :selected="selected"
      :items="groupedTextSets"
      @change-selected-at-tab="changeSelectedAtTab"
      @reload="getTextSets"
      @open-complete-dialog="(url, type) => $refs.completeDialog.show(url, type)"
      @change-creator-comment="changeCreatorComment"
    />

    <complete-dialog ref="completeDialog" />

    <regist-text-set-dialog
      v-if="$route.path !== appRoute.CREATIVE_REGIST"
      ref="registTextSetDialog"
      :default-promotion-id="selectedPromotionIds[0]"
      :ad-formats="adFormats"
      :tags="tags"
      @add-ad-format="addAdFormat"
    />
  </el-container>
</template>

<style>
#text-master .el-main {
  padding: 10px 20px 10px 20px !important;
}
#text-master .el-tabs__content {
  padding: 0;
}
</style>

<style scoped>
.p-15px {
  padding: 15px;
}
.footer {
  padding: 10px 20px 10px;
}
.w-0 {
  width: 0;
}
.w-100 {
  width: 100%;
}
.bg-dark {
  background: #d4d4d4;
}
.create-new {
  border: none;
  box-shadow: 0px 8px 10px rgb(0 0 0 / 50%);
  position: fixed;
  bottom: 30px;
  right: 40px;
  color: white;
  background-color: #409eff;
  border-radius: 10px;
  font-size: inherit;
  font-weight: bold;
}
.create-new:hover {
  color: white;
  background-color: #66b1ff;
}
.search-icon {
  border-radius: 25px;
  padding: 7px;
  position: relative;
  top: 50%;
  font-size: 1em;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import AdFormatTab from '@/views/text-master/ad-format-tab'
import SelectAdFormatDialog from '@/components/select-ad-format-dialog'
import DrawerText from '@/views/text-master/components/drawer'
import SelectBar from '@/components/select-bar'
import RegistTextSetDialog from '@/views/text-master/regist-text-set-dialog'
import ApprovalRequestDialog from '@/components/approval-request-dialog'
import CompleteDialog from '@/components/complete-dialog'
import { AppRoute } from '@/mixins/appRoute'
import { MessageBox } from 'element-ui'
import * as XLSX from 'xlsx'
export default {
  name: 'text-master',
  mixins: [util],
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    adFormatId: { type: String, default: () => '' },
  },
  components: {
    SelectAdFormatDialog,
    AdFormatTab,
    DrawerText,
    SelectBar,
    RegistTextSetDialog,
    ApprovalRequestDialog,
    CompleteDialog,
  },
  data: () => ({
    loading: false,
    groupedTextSets: [],
    selected: [],
    activeTab: 'add',
    tabs: [],
    allTabs: [],
    isDrawerShow: false,
    innerHeight: window.innerHeight,
    adFormatIds: [],
    displayType: 'default',
    query: {},
    appRoute: AppRoute,
  }),
  async created() {
    window.TextMaster = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })

    if (_.includes(['/creative-regist', '/wall-hitting'], this.$route.path)) this.isDrawerShow = true

    if (this.$route.query.creativeDraftId) this.$router.push({ query: _.omit(this.$route.query, ['creativeDraftId']) })

    if (this.$route.query.wallHittingResultId || this.$route.query.wallHittingDraftId) {
      this.$router.push({
        query: _.omit(this.$route.query, ['wallHittingResultId', 'wallHittingDraftId', 'page', 'adAccountId']),
      })
    }

    if (this.$route.query.approvalRequestIds || this.$route.query.approvalRequestId) {
      this.query.approvalRequestIds = [this.$route.query.approvalRequestIds || this.$route.query.approvalRequestId]
      this.displayType = 'approvalRequest'
      await this.getTextSets()
    }

    if (this.$route.query.tagValue && this.$route.query.tagTypeId && this.$route.query.textSetId) {
      await this.getTextSets({
        tags: [
          {
            tagValues: [this.$route.query.tagValue],
            isAnd: true,
            tagTypeId: this.$route.query.tagTypeId,
          },
        ],
      })

      this.selected = _.chain(this.groupedTextSets)
        .map(r => _.map(r.textSets, r2 => _.assign({}, r2, { adFormat: r.adFormat })))
        .flattenDeep()
        .filter(r => r.textSetId === this.$route.query.textSetId)
        .map(r => ({ adFormatId: r.adFormat.id, textSetId: r.textSetId }))
        .uniqBy('textSetId')
        .value()

      this.activeTab = _.chain(this.tabs)
        .find(r => {
          const textSetIds = _.map(this.groupByTextSets[r.adFormatId], 'textSetId')
          return _.includes(textSetIds, this.$route.query.textSetId)
        })
        .result('name')
        .value()

      this.$router.push({ query: _.omit(this.$route.query, ['tagValue', 'tagTypeId', 'textSetId']) })
    }

    if (_.includes(['/text-master'], this.$route.path) && this.$route.query.textSetIds) {
      await this.getTextSets()
    }

    if (this.$route.query.creativeIds) this.$router.push({ query: _.omit(this.$route.query, ['creativeIds']) })
  },
  methods: {
    log(a, b) {
      console.log(a, b)
    },
    toggleSearch() {
      this.isDrawerShow = !this.isDrawerShow
    },
    toggleDisplayType() {
      this.isDrawerShow = false
    },
    openTagDialog() {
      const params = {
        targetType: 'textSet',
        selectedTags: this.selectedTags,
        targetIds: _.map(this.selected, 'textSetId'),
      }
      this.$root.$children[0].$refs.tagDialog.show(params)
    },
    async deleteSelected() {
      const ret = await this.$confirm('テキストを削除しますか?', '確認', {
        customClass: 'delete-confirmation',
      }).catch(e => e)
      if (ret === 'cancel') return
      const param = _.map(this.selected, 'textSetId')
      this.loading = true
      const res = await this.$api.authFetch('/text_set', {
        method: 'DELETE',
        query: { textSetId: param },
      })

      if (typeof res !== 'undefined') {
        if (res === 'クリエイティブに使用されているので削除できません。') {
          MessageBox.alert(res, 'エラー', {
            showCancelButton: true,
            distinguishCancelAndClose: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'クリエイティブを確認する',
            customClass: 'delete-confirmation',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm' || action === 'close') {
                done()
                this.loading = false
              } else {
                const query = _.assign({}, this.$route.query, {
                  textSetIds: _.map(this.selected, 'textSetId'),
                })
                const routeData = this.$router.resolve({ name: `creative-list`, query: query })
                window.open(`${window.location.origin}${routeData.href}`, '_blank')
              }
            },
          }).catch(action => {
            if (action == 'close') {
              this.loading = false
            }
          })
        } else {
          this.selected = []
          await this.getTextSets()
          this.loading = false
        }
      }

      this.loading = false
    },
    changeCreatorComment(creatorComment, textSetId, platformId) {
      this.groupedTextSets = _.chain(this.groupedTextSets)
        .cloneDeep()
        .map(r => {
          if (r.adFormat.platformId !== platformId) return r

          const textSets = _.map(r.textSets, r2 =>
            r2.textSetId !== textSetId ? r2 : _.assign({}, r2, { creatorComment })
          )
          return _.assign({}, r, { textSets })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => (r.textSetId !== textSetId ? r : _.assign({}, r, { creatorComment })))
          .value()
      }
    },
    changeSelectedAtTab({ selected, adFormatId, platformId, adFormatName }) {
      const formatedSelected = _.map(selected, textSetId => {
        const textSet = _.find(this.groupByTextSets[adFormatId], r2 => r2.textSetId === textSetId)
        return _.assign({}, textSet, { adFormatId, platformId, adFormatName })
      })
      if (this.selected.length === 0) {
        this.selected = formatedSelected
        return
      }

      this.selected = _.chain(this.selected)
        .filter(r => r.adFormatId !== adFormatId)
        .concat(formatedSelected)
        .uniqBy('textSetId')
        .value()
    },
    changeSelected(isAll, isOnlyTab, isDeselectTab) {
      if (!isAll) {
        this.selected = []
        return
      }

      const selectedAdFormatIds = isOnlyTab
        ? _.chain(this.tabs)
            .filter(r => r.platformId === this.activeTab)
            .map(r => _.filter(r.textSets, r2 => r2.name === r.activeName))
            .flattenDeep()
            .map('adFormatId')
            .filter()
            .uniq()
            .value()
        : _.chain(this.tabs).map('textSets').flattenDeep().map('adFormatId').filter().uniq().value()
      const textList = _.chain(this.allTabs)
        .map(r => _.map(r.textSets, r2 => ({ platformId: r.platformId, adFormatId: r2.adFormatId })))
        .flattenDeep()
        .filter(r => _.includes(selectedAdFormatIds, r.adFormatId))
        .map(r => _.map(this.groupByTextSets[r.adFormatId], r2 => _.assign({}, r, r2)))
        .flattenDeep()
        .map(r => {
          return _.assign({}, r, { adFormatName: this.getAdformatName(r.adFormatId) })
        })
        .value()

      this.selected = isDeselectTab
        ? this.selected.filter(x => {
            return !_.includes(
              textList.map(y => _.isEqual(y, x)),
              true
            )
          })
        : textList
    },
    changeSelectedForCreativeRegist(selected) {
      this.selected = _.filter(this.selected, r => _.includes(selected, r.textSetId))
    },
    clickTab({ label }) {
      if (label === 'add') this.$refs.selectAdFormatDialog.show()
    },
    async removeTab(targetName, isParent, platformId) {
      this.tabs = []
      await this.$nextTick()

      if (isParent) {
        this.tabs = _.filter(this.allTabs, r => r.platformId !== targetName)
        if (this.activeTab === targetName) {
          this.activeTab =
            _.map(this.tabs, 'platformId').length === 0 ? 'add' : _.chain(this.tabs).map('platformId').first().value()
        }
      } else {
        const parentNames = _.map(this.allTabs, 'platformId')
        this.tabs = _.chain(this.allTabs)
          .map(r => {
            const childrenNames = _.map(r.textSets, 'name')
            const activeName =
              r.activeName === targetName
                ? r.activeName
                : childrenNames.length === 0
                ? ''
                : childrenNames[_.indexOf(childrenNames, targetName) + 1]
            const textSets = _.filter(r.textSets, r2 => r2.name !== targetName)
            return _.assign({}, r, { activeName, textSets })
          })
          .filter(r => r.textSets.length !== 0)
          .value()

        if (this.activeName !== platformId) {
          this.activeTab = parentNames.length === 0 ? 'add' : parentNames[_.indexOf(parentNames, platformId)]
        }

        this.tabs = _.chain(this.allTabs)
          .mapValues(r => {
            const textSets = _.filter(r.textSets, r2 => r2.name !== targetName)
            return textSets.length === 0 ? {} : _.assign({}, r, { textSets })
          })
          .omitBy(_.isEmpty)
          .value()
      }

      this.allTabs = _.cloneDeep(this.tabs)
      if (_.isEmpty(this.tabs)) return

      const keyBySelectedAdFormatIds = _.chain(this.tabs)
        .map('textSets')
        .flattenDeep()
        .uniqBy('adFormatId')
        .keyBy('adFormatId')
        .value()

      this.selected = _.filter(this.selected, r => keyBySelectedAdFormatIds[r.adFormatId])
    },

    addAdFormat(gr) {
      this.adFormatIds = _.chain(this.tabs)
        .map('textSets')
        .flattenDeep()
        .map('adFormatId')
        .uniq()
        .thru(ret => (!gr ? ret : _.concat(ret, gr)))
        .filter()
        .uniq()
        .value()

      if (this.adFormatIds.length === 0) {
        this.groupedTextSets = []
        this.tabs = []
        this.allTabs = []
        this.activeTab = 'add'
        return
      }

      this.getTextSets()
    },
    async getTextSets(params) {
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds
        : [this.$route.query.promotionIds]

      if (this.adFormatId) params = _.assign(params, { adFormatIds: [this.adFormatId] })
      else if (!params && !this.$route.query.textSetIds) {
        const adFormatIds =
          this.adFormatIds.length === 0
            ? _.chain(this.tabs).map('adFormatId').filter().uniq().value()
            : this.adFormatIds
        params = _.assign({ adFormatIds }, _.isEmpty(this.query) ? this.$refs.drawer.getFormParams() : this.query)
      }

      let body = _.assign({}, params, {
        promotionIds: this.selectedPromotionIds.length !== 0 ? this.selectedPromotionIds : urlPromotionId,
      })
      if (this.$route.query.textSetIds) {
        const textSetIds = _.isArray(this.$route.query.textSetIds)
          ? this.$route.query.textSetIds
          : [this.$route.query.textSetIds]
        body = _.assign({}, body, { textSetIds: textSetIds })
      }

      this.loading = true
      this.groupedTextSets = _.result(
        await this.$api.authFetch('/text_set/list', { method: 'POST', body }),
        'groupedTextSets'
      )
      this.loading = false

      if (this.groupedTextSets.length === 0) this.$alert('結果は0件です', '検索結果')

      this.tabs = _.chain(this.groupedTextSets)
        .map(({ adFormat: { id: adFormatId, name, platformId } }) => ({ name, adFormatId, platformId }))
        .uniqBy(r => r.adFormatId + r.platformId)
        .groupBy('platformId')
        .map((gr, platformId) => {
          const textSets = _.orderBy(gr, ['name'])
          const activeName =
            this.tabs.length === 0
              ? _.result(textSets, '[0].name')
              : _.chain(this.tabs)
                  .find(r => r.platformId === platformId)
                  .result('activeName')
                  .value()
          return { platformId, activeName, textSets }
        })
        .orderBy(['platformId'])
        .value()

      this.allTabs = _.cloneDeep(this.tabs)
      if (this.groupedTextSets.length === 0) this.activeTab = 'add'
      else if (this.activeTab === 'add') this.activeTab = _.result(this.tabs, '[0].platformId')
      else if (!_.chain(this.allTabs).map('platformId').includes(this.activeTab).value()) {
        this.activeTab = _.chain(this.allTabs).last().result('platformId').value()
      }
    },
    async reset() {
      if (!_.includes(['/creative-regist', '/wall-hitting'], this.$route.path)) return

      this.selected = []
      this.groupedTextSets = []
      this.tabs = []
      this.allTabs = []
      this.activeTab = 'add'
      await this.getTextSets({
        adFormatIds: [this.adFormatId],
        promotionIds: this.selectedPromotionIds,
      })
    },
    goToCreativeList() {
      const query = _.assign({}, this.$route.query, {
        textSetIds: _.map(this.selected, 'textSetId'),
      })
      this.$router.push({ path: '/creative-list', query })
    },
    initializeColumn(adFormatId) {
      const textLabels = this.adFormats
        .find(x => x.id === adFormatId)
        .textLabels.sort((a, b) => {
          return a.labelIndex - b.labelIndex
        })
      const textLabelsColumn = textLabels.map(x => x.name)
      return ['商材', 'プラットフォーム', '広告フォーマット名']
        .concat(textLabelsColumn)
        .concat(['OddAi予測結果', '制作者コメント', '監修ステータス', 'タグ', '非承認コメント'])
    },
    convertArr(arr) {
      return arr
        .map(k => {
          return k
        })
        .join('\n')
    },
    buildXlsxdata(textSetList, adFormatId) {
      const textLabels = this.adFormats
        .find(x => x.id === adFormatId)
        .textLabels.sort((a, b) => {
          return a.labelIndex - b.labelIndex
        })
      return textSetList.map(text => {
        let textValueObj = []
        textLabels.forEach(label => {
          const existLabeledText = text.labeledTexts.find(x => x.labelName === label.name)
          if (!existLabeledText) {
            textValueObj.push('')
          } else {
            textValueObj.push(this.convertArr(existLabeledText.textValues))
          }
        })

        return [this.$root.$children[0].selectedPromotions[0].name, text.platformId, text.adFormatName]
          .concat(textValueObj)
          .concat([
            text.score ? text.score : '',
            text.creatorComment ? text.creatorComment : '',
            text.approvalStatus ? text.approvalStatus : '',
            text.tags ? this.convertArr(text.tags.map(x => x.tagValue)) : '',
            text.notApprovalReason ? this.convertArr(text.notApprovalReason) : '',
          ])
      })
    },
    formatDateXlsx(date) {
      const year = date.substring(0, 4)
      const month = date.substring(4, 6)
      const day = date.substring(6, 8)
      const hour = date.substring(8, 10)
      const minute = date.substring(10, 12)
      const second = date.substring(12, 14)

      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    },
    formatTime(time) {
      return time < 10 ? '0' + time : time
    },
    getCurrentTime() {
      const now = new Date()
      return `${now.getFullYear().toString()}${this.formatTime(now.getMonth() + 1)}${this.formatTime(
        now.getDate()
      )}${this.formatTime(now.getUTCHours() + 9)}${this.formatTime(now.getMinutes())}${this.formatTime(
        now.getSeconds()
      )}`
    },
    downloadFile() {
      const fileName = `${this.getCurrentTime()}_${this.$root.$children[0].selectedPromotions[0].name}_text_set.xlsx`
      XLSX.writeFileXLSX(this.xlsxData, fileName)
    },
    getPlatformName(platformId) {
      return _.chain(this.adFormats)
        .find(r => platformId === r.platformId)
        .result('platformName')
        .value()
    },
    getAdformatName(adFormatId) {
      return _.chain(this.adFormats)
        .find(r => adFormatId === r.id)
        .result('name')
        .value()
    },
  },
  watch: {
    selectedPromotionIds() {
      if (this.tabs.length === 0) return
      this.getTextSets(this.adFormatId ? { adFormatIds: [this.adFormatId] } : undefined)
    },
    selected() {
      if (!_.includes(['/creative-regist', '/wall-hitting'], this.$route.path)) return
      const selectedTextSets = _.map(this.selected, r =>
        _.find(this.groupByTextSets[r.adFormatId], r2 => r2.textSetId === r.textSetId)
      )
      this.$emit('change-selected-text-sets', selectedTextSets)
    },
  },
  computed: {
    tabHeight() {
      const height = _.includes(['/creative-regist', '/wall-hitting'], this.$route.path)
        ? this.selected.length > 0
          ? this.innerHeight - 50 - 41 - 20 - 70 - 55
          : this.innerHeight - 50 - 41 - 20 - 70
        : this.innerHeight - 60 - 41 - 41 - 10 - 15 - 15 - 20

      return (this.$route.path === '/wall-hitting' ? 41 : 0) + (this.tabs.length === 0 ? height : height - 41)
    },
    drawerHeight() {
      const height = _.includes(['/creative-regist', '/wall-hitting'], this.$route.path)
        ? this.innerHeight - 55 - 41 - 16 - 16 - 70
        : this.innerHeight - 60 - 32

      return (this.$route.path === '/wall-hitting' ? 41 : 0) + height
    },
    allPlatforms() {
      return _.chain(this.adFormats).map('platformId').uniq().value()
    },
    groupByTextSets() {
      return _.chain(this.groupedTextSets)
        .keyBy('adFormat.id')
        .mapValues(r =>
          _.map(r.textSets, r2 => {
            const labeledTexts = _.orderBy(r2.labeledTexts, 'index')
            return _.assign({}, r2, { labeledTexts })
          })
        )
        .value()
    },
    tagIdByTargets() {
      return _.chain(this.groupedTextSets)
        .map('textSets')
        .flattenDeep()
        .map(r => _.map(r.tags, r2 => _.assign({}, r2, { targetId: r.textSetId })))
        .flattenDeep()
        .groupBy('tagId')
        .map(gr => ({
          targetIds: _.map(gr, 'targetId'),
          tagId: _.first(gr).tagId,
        }))
        .value()
    },
    selectedTags() {
      const targetIdsAll = _.map(this.selected, 'textSetId')
      return _.chain(this.selected)
        .map(r =>
          _.chain(this.tagIdByTargets)
            .filter(r2 => _.includes(r2.targetIds, r.textSetId))
            .map(r2 => ({ targetId: r.textSetId, tagId: r2.tagId }))
            .value()
        )
        .flattenDeep()
        .groupBy('tagId')
        .mapValues(gr => {
          const selectedTargetIds = _.map(gr, 'targetId')
          return {
            selectedTargetIds,
            isAllSelected: selectedTargetIds.length === targetIdsAll.length,
          }
        })
        .value()
    },
    xlsxData() {
      const selectedWithSheetName = _.chain(this.selected)
        .cloneDeep()
        .groupBy('platformId')
        .mapValues(x => _.chain(x).groupBy('adFormatId').value())
        .value()

      const workbook = XLSX.utils.book_new()

      for (const sheetName in selectedWithSheetName) {
        const data = selectedWithSheetName[sheetName]
        const sheetData = []
        for (const adFormatId in data) {
          const column = this.initializeColumn(adFormatId)
          const content = this.buildXlsxdata(data[adFormatId], adFormatId)
          sheetData.push(column)
          content.forEach(x => sheetData.push(x))
          sheetData.push([])
        }

        const worksheet = XLSX.utils.aoa_to_sheet(sheetData)
        XLSX.utils.book_append_sheet(workbook, worksheet, this.getPlatformName(sheetName))
      }
      return workbook
    },
  },
}
</script>
