<template>
  <el-dialog
    id="comfirm-dialog"
    custom-class="small-dialog"
    :title="`確認 ${typeStr}します`"
    lock-scroll
    append-to-body
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <ul>
      <li v-for="({ platformId, adFormatName, length }, i) in confirmMessages" :key="i">
        {{ platformId }} {{ adFormatName }} {{ length }}本
      </li>
    </ul>

    <div v-if="containZipOrUrl" class="text-red pl-20px mb-1">
      ※Zip,YoutubeUrlファイル選択のクリエイティブに対して、規定チェックできなくなります。ご注意ください。
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close('regist')"> アセットCR作成 </el-button>
    </span>
  </el-dialog>
</template>

<style>
#comfirm-dialog .el-dialog__body {
  padding: 20px !important;
}
</style>

<style scoped>
.text-red {
  color: #f56c6c;
}
.pl-20px {
  padding-left: 20px;
}
.mb-1 {
  margin-bottom: 1rem;
}
</style>

<script>
export default {
  name: 'comfirm-dialog',
  props: {
    typeStr: { type: String, default: () => '' },
    confirmMessages: { type: Array, default: () => [] },
    containZipOrUrl: { type: Boolean, default: () => false },
  },
  data: () => ({ isShow: false }),
  created() {
    window.ComfirmDialog = this
  },
  methods: {
    show() {
      this.isShow = true
    },
    close(action) {
      this.isShow = false
      this.$emit(action)
    },
  },
}
</script>
