<template>
  <el-container id="image-movie-master" v-loading="loading">
    <el-col :span="24">
      <select-bar
        :selected="selected"
        :is-tabs-exists="tabs.length !== 0"
        :display-error-asset="displayErrorAsset"
        :is-search-open="isDrawerShow"
        :is-display-type-open="isDisplayTypeDrawerShow"
        target-type="asset"
        @reload="d => getAssets()"
        @go-to-creative-list="goToCreativeList"
        @change-selected="changeSelected"
        @open-tag-dialog="openTagDialog"
        @open-approval-request-dialog="$refs.approvalRequestDialog.show('asset')"
        @is-display-error-asset="
          v => {
            this.displayErrorAsset = v
          }
        "
        @download-selected="e => downloadSelected(e)"
        @toggle-search="toggleSearch"
        @toggle-display-type="toggleDisplayType"
        @delete-selected="deleteSelected"
      />

      <el-collapse-transition>
        <drawer-asset
          v-show="isDrawerShow"
          ref="drawer"
          :tags="tags"
          :selected-promotion-ids="selectedPromotionIds"
          @get-assets="getAssets"
        />
      </el-collapse-transition>
      <el-main
        v-if="
          $route.path !== appRoute.CREATIVE_REGIST ||
          ($route.path === appRoute.CREATIVE_REGIST && tabs.length !== 0) ||
          $route.path !== appRoute.CREATIVE_REGIST_ONLY ||
          ($route.path === appRoute.CREATIVE_REGIST_ONLY && tabs.length !== 0)
        "
      >
        <el-tabs
          v-model="activeTab"
          type="border-card"
          @tab-remove="name => removeTab(name, true)"
          @tab-click="clickTab"
          :disabled="[appRoute.CREATIVE_REGIST, appRoute.CREATIVE_REGIST_ONLY].includes(this.$route.path)"
        >
          <el-tab-pane label="add" name="add" class="p-15px">
            <span slot="label">
              <i class="fas fa-plus-circle" />
            </span>

            <div :style="{ height: tabHeight - 15 + 'px' }">
              <div v-if="![appRoute.CREATIVE_REGIST, appRoute.CREATIVE_REGIST_ONLY].includes(this.$route.path)">
                <div>【スタート方法】</div>
                <ul>
                  <li>
                    素材サイズを指定して表示:
                    <i class="fas fa-plus-circle" /> ボタンを押してサイズを選択
                  </li>
                  <li>詳細検索: サイドバーの検索ボタン（検索条件無しで「検索」を押すと全件表示）</li>
                </ul>
              </div>
              <div v-else>
                <div>検索条件を指定して検索してください （検索条件無しで「適用」を押すと全件表示)</div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane
            id="inner-tabs"
            v-for="(r, j) in filteredAssetTab"
            :key="displayErrorAsset ? j : j + 'no-error-asset'"
            :name="r.aspectRatio"
            :label="r.aspectRatio"
            :closable="$route.path !== appRoute.CREATIVE_REGIST"
            lazy
          >
            <span slot="label">
              <i class="fas fa-home" />
              {{
                r.aspectRatio === '0:1'
                  ? 'ZIP'
                  : r.aspectRatio === '0:2'
                  ? 'Youtube動画'
                  : r.aspectRatio === '0:3'
                  ? '音楽'
                  : r.aspectRatio
              }}
            </span>

            <el-tabs
              v-model="r.activeName"
              type="border-card"
              @tab-remove="name => removeTab(name, false, r.aspectRatio)"
            >
              <el-tab-pane
                v-for="({ name, pixelSize }, i) in r.assets"
                :key="displayErrorAsset ? i : i + 'no-error-asset'"
                :name="name"
                :label="getLabelName(name)"
                :closable="$route.path !== appRoute.CREATIVE_REGIST"
                lazy
                class="p-15px"
              >
                <pixel-size-tab
                  :selected="selected"
                  :oldSelected="oldSelected"
                  :whitelist="whitelist"
                  :assets="groupByAssets[`${pixelSize.width}_${pixelSize.height}`]"
                  :pixel-size="pixelSize"
                  :aspect-ratio="r.aspectRatio"
                  :height="tabHeight"
                  :display-type="displayType"
                  @change-selected-at-tab="changeSelectedAtTab"
                  @change-creator-comment="changeCreatorComment"
                  @get-assets="getAssets"
                />
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
        </el-tabs>

        <el-button
          class="create-new"
          v-if="$route.path === appRoute.IMAGE_MOVIE_MASTER"
          @click="$refs.uploadAssetsDialog.show()"
        >
          <i class="fas fa-cloud-upload-alt" style="font-size: larger"></i>
          画像/動画をアップロード
        </el-button>
      </el-main>

      <el-main v-else> 検索条件を指定して検索してください （検索条件無しで「適用」を押すと全件表示) </el-main>
    </el-col>

    <approval-request-dialog
      ref="approvalRequestDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :selected="selected"
      :items="groupedAssets"
      @change-selected-at-tab="changeSelectedAtTab"
      @reload="getAssets"
      @open-complete-dialog="(url, type) => $refs.completeDialog.show(url, type)"
      @change-creator-comment="changeCreatorComment"
    />

    <complete-dialog ref="completeDialog" />

    <upload-assets-dialog
      ref="uploadAssetsDialog"
      :default-promotion-id="selectedPromotionIds[0]"
      :tags="tags"
      :adFormats="adFormats"
    />

    <select-asset-specs-dialog
      v-if="$route.path !== appRoute.CREATIVE_REGIST"
      ref="selectAssetSpecsDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :assetSpecs="assetSpecs"
      :zipFileCount="zipFileCount"
      :youtubeUrlCount="youtubeUrlCount"
      :musicFileCount="musicFileCount"
      @add-asset-specs="addAssetSpecs"
    />
  </el-container>
</template>

<style>
#image-movie-master .el-main {
  padding: 10px 20px 10px 20px !important;
}
#image-movie-master .el-tabs__content {
  padding: 0;
}
</style>

<style scoped>
.p-15px {
  padding: 15px;
}
.p-1 {
  padding: 1rem;
}
.w-0 {
  width: 0;
}
.create-new {
  border: none;
  box-shadow: 0px 8px 10px rgb(0 0 0 / 50%);
  position: fixed;
  bottom: 30px;
  right: 40px;
  color: white;
  background-color: #409eff;
  border-radius: 10px;
  font-size: inherit;
  font-weight: bold;
  z-index: 1;
}
.create-new:hover {
  color: white;
  background-color: #66b1ff;
}

.search-icon {
  border-radius: 25px;
  padding: 7px;
  position: relative;
  top: 50%;
  font-size: 1em;
  right: 15px;
}
.bg-black {
  background: #004b6a;
}
</style>

<script>
import _ from 'lodash'
import SelectBar from '@/components/select-bar'
import PixelSizeTab from '@/views/image-movie-master/pixel-size-tab'
import SelectAssetSpecsDialog from '@/views/image-movie-master/components/select-asset-specs-dialog'
import ApprovalRequestDialog from '@/components/approval-request-dialog'
import CompleteDialog from '@/components/complete-dialog'
import UploadAssetsDialog from '@/components/upload-assets-dialog'
import DrawerAsset from '@/views/image-movie-master/components/drawer-asset'
import { AppRoute } from '@/mixins/appRoute'
import { MessageBox } from 'element-ui'

export default {
  name: 'image-movie-master',
  components: {
    SelectBar,
    SelectAssetSpecsDialog,
    PixelSizeTab,
    ApprovalRequestDialog,
    CompleteDialog,
    UploadAssetsDialog,
    DrawerAsset,
  },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    assetSpecs: { type: Array, default: () => [] },
    zipFileCount: { type: Number, default: () => 0 },
    youtubeUrlCount: { type: Number, default: () => 0 },
    musicFileCount: { type: Number, default: () => 0 },
    adFormatId: { type: String, default: () => '' },
    extensions: { type: Array, default: () => [] },
    labelId: { type: String, default: () => '' },
    isCreative: { type: Boolean, default: () => false },
    whitelist: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
  },
  data: () => ({
    loading: false,
    groupedAssets: [],
    selected: [],
    oldSelected: [],
    isDisplayTypeDrawerShow: false,
    activeTab: 'add',
    tabs: [],
    allTabs: [],
    innerHeight: window.innerHeight,
    displayType: 'default',
    pixelSizes: [],
    query: {},
    displayErrorAsset: true,
    isDrawerShow: false,
    appRoute: AppRoute,
  }),
  async created() {
    window.ImageMovieMaster = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })

    if (_.includes(['/creative-regist', '/wall-hitting', '/creative-regist-only'], this.$route.path))
      this.isDrawerShow = true

    if (this.$route.query.creativeDraftId) this.$router.push({ query: _.omit(this.$route.query, ['creativeDraftId']) })

    if (this.$route.query.wallHittingResultId || this.$route.query.wallHittingDraftId) {
      this.$router.push({
        query: _.omit(this.$route.query, ['wallHittingResultId', 'wallHittingDraftId', 'page', 'adAccountId']),
      })
    }

    if (this.$route.query.approvalRequestIds || this.$route.query.approvalRequestId) {
      this.query.approvalRequestIds = [this.$route.query.approvalRequestIds || this.$route.query.approvalRequestId]
      this.displayType = 'approvalRequest'
      await this.getAssets()
    }

    if (this.$route.query.tagValue && this.$route.query.tagTypeId && this.$route.query.originIds) {
      await this.getAssets({
        tags: [
          {
            tagValues: [this.$route.query.tagValue],
            isAnd: true,
            tagTypeId: this.$route.query.tagTypeId,
          },
        ],
      })

      const originIds = _.isArray(this.$route.query.originIds)
        ? this.$route.query.originIds
        : [this.$route.query.originIds]

      this.selected = _.chain(this.groupedAssets)
        .map(r => _.map(r.assets, r2 => _.assign({}, r2, { pixelSize: r.pixelSize })))
        .flattenDeep()
        .filter(r => _.includes(originIds, r.originId))
        .map(r => ({ originId: r.originId, pixelSize: r.pixelSize }))
        .uniqBy('originId')
        .value()

      this.activeTab = _.chain(this.tabs)
        .find(r => {
          const originIds = _.map(this.groupByAssets[`${r.pixelSize.width}_${r.pixelSize.height}`], 'originId')
          return _.find(originIds, v => _.includes(this.$route.query.originIds, v))
        })
        .result('title')
        .value()

      this.$router.push({ query: _.omit(this.$route.query, ['tagValue', 'tagTypeId', 'originIds']) })
    }

    if (_.includes(['/image-movie-master'], this.$route.path) && this.$route.query.originIds) {
      await this.getAssets()
    }

    if (this.$route.query.creativeIds) this.$router.push({ query: _.omit(this.$route.query, ['creativeIds']) })
    if (this.$route.query.creativeListForm)
      this.$router.push({ query: _.omit(this.$route.query, ['creativeListForm']) })
  },
  methods: {
    toggleSearch() {
      this.isDisplayTypeDrawerShow = false
      this.isDrawerShow = !this.isDrawerShow
    },
    toggleDisplayType() {
      this.isDrawerShow = false
      this.isDisplayTypeDrawerShow = !this.isDisplayTypeDrawerShow
    },
    getLabelName(name) {
      switch (name) {
        case '0×1':
          return 'ZIP'
        case '0×2':
          return 'Youtube動画'
        case '0×3':
          return '音楽'
        default:
          return name
      }
    },
    changeCreatorComment(creatorComment, originId, aspectRatio) {
      this.groupedAssets = _.chain(this.groupedAssets)
        .cloneDeep()
        .map(r => {
          if (r.aspectRatio !== aspectRatio) return r

          const assets = _.map(r.assets, r2 => (r2.originId !== originId ? r2 : _.assign({}, r2, { creatorComment })))
          return _.assign({}, r, { assets })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => (r.originId !== originId ? r : _.assign({}, r, { creatorComment })))
          .value()
      }
    },
    openTagDialog() {
      const params = {
        targetType: 'asset',
        selectedTags: this.selectedTags,
        targetIds: _.map(this.selected, 'originId'),
      }
      this.$root.$children[0].$refs.tagDialog.show(params)
    },
    clickTab({ label }) {
      if (label === 'add' && !_.includes(['/creative-regist', '/creative-regist-only'], this.$route.path))
        this.$refs.selectAssetSpecsDialog.show()
    },
    async removeTab(targetName, isParent, aspectRatio) {
      this.tabs = []
      await this.$nextTick()

      if (isParent) {
        this.tabs = _.filter(this.allTabs, r => r.aspectRatio !== targetName)
        if (this.activeTab === targetName) {
          this.activeTab =
            _.map(this.tabs, 'aspectRatio').length === 0 ? 'add' : _.chain(this.tabs).map('aspectRatio').first().value()
        }
      } else {
        const parentNames = _.map(this.allTabs, 'aspectRatio')
        this.tabs = _.chain(this.allTabs)
          .map(r => {
            const childrenNames = _.map(r.assets, 'name')
            const activeName =
              r.activeName === targetName
                ? r.activeName
                : childrenNames.length === 0
                ? ''
                : childrenNames[_.indexOf(childrenNames, targetName) + 1]
            const assets = _.filter(r.assets, r2 => r2.name !== targetName)
            return _.assign({}, r, { activeName, assets })
          })
          .filter(r => r.assets.length !== 0)
          .value()

        if (this.activeName !== aspectRatio) {
          this.activeTab = parentNames.length === 0 ? 'add' : parentNames[_.indexOf(parentNames, aspectRatio)]
        }
      }

      this.allTabs = _.cloneDeep(this.tabs)
      if (_.isEmpty(this.tabs)) return

      const keyBySelectedPixelSizes = _.chain(this.tabs)
        .map('assets')
        .flattenDeep()
        .uniqBy(r => `${r.pixelSize.width}_${r.pixelSize.height}`)
        .keyBy(r => `${r.pixelSize.width}_${r.pixelSize.height}`)
        .value()

      this.selected = _.filter(
        this.selected,
        r => keyBySelectedPixelSizes[`${r.pixelSize.width}_${r.pixelSize.height}`]
      )
    },
    addAssetSpecs(gr) {
      this.pixelSizes = _.chain(this.tabs)
        .map('assets')
        .flattenDeep()
        .map('pixelSize')
        .uniqBy(r => `${r.width}_${r.height}`)
        .thru(ret => (!gr ? ret : _.concat(ret, gr)))
        .filter()
        .uniq()
        .value()

      if (this.pixelSizes.length === 0) {
        this.groupedAssets = []
        this.tabs = []
        this.activeTab = 'add'
        return
      }

      this.getAssets()
    },
    async getAssets(params) {
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds
        : [this.$route.query.promotionIds]

      if (!params && !this.$route.query.originIds) {
        const pixelSizes =
          this.pixelSizes.length === 0 ? _.chain(this.tabs).map('pixelSize').filter().uniq().value() : this.pixelSizes
        params = _.assign({ pixelSizes }, _.isEmpty(this.query) ? this.$refs.drawer.getFormParams() : this.query)
      }

      this.loading = true
      let zipResult = []
      let youtubeUrlResult = []
      if (this.isCreative && this.labelId) {
        params = _.assign(params, { labelIds: [this.labelId], promotionIds: urlPromotionId })
        let bodyZip = _.omit(params, ['aspectRatios', 'labelIds', 'pixelSizes'])
        bodyZip = _.assign(bodyZip, { extensions: ['zip'] })
        let bodyYoutubeUrl = _.omit(params, ['aspectRatios', 'labelIds', 'pixelSizes'])
        bodyYoutubeUrl = _.assign(bodyYoutubeUrl, { extensions: ['youtube'] })
        zipResult = _.result(
          await this.$api.authFetch('/asset/list', { method: 'POST', body: bodyZip }),
          'groupedAssets'
        )
        youtubeUrlResult = _.result(
          await this.$api.authFetch('/asset/list', { method: 'POST', body: bodyYoutubeUrl }),
          'groupedAssets'
        )
      }

      let body = _.assign({}, params, {
        promotionIds: this.selectedPromotionIds.length !== 0 ? this.selectedPromotionIds : urlPromotionId,
      })
      if (this.$route.query.originIds) {
        const originIds = _.isArray(this.$route.query.originIds)
          ? this.$route.query.originIds
          : [this.$route.query.originIds]
        body = _.assign({}, body, { originIds: originIds })
      }

      this.groupedAssets = _.result(await this.$api.authFetch('/asset/list', { method: 'POST', body }), 'groupedAssets')
        .concat(zipResult)
        .concat(youtubeUrlResult)
      this.loading = false

      if (this.groupedAssets.length === 0)
        this.$alert('結果は0件です', '検索結果', {
          confirmButtonText: 'キャンセル',
        }).catch(e => e)

      const tabs = _.chain(this.groupedAssets)
        .map(({ aspectRatio, pixelSize }) => ({
          name: `${pixelSize.width}×${pixelSize.height}`,
          aspectRatio,
          pixelSize,
        }))
        .uniqBy(r => r.name + r.aspectRatio)
        .groupBy('aspectRatio')
        .map((gr, aspectRatio) => {
          const assets = _.orderBy(gr, ['pixelSize.width', 'pixelSize.height'])
          const activeName =
            this.tabs.length === 0
              ? _.result(assets, '[0].name')
              : _.chain(this.tabs)
                  .find(r => r.aspectRatio === aspectRatio)
                  .thru(r => _.result(r, 'activeName') || _.result(assets, '[0].name'))
                  .value()
          return { aspectRatio, activeName, assets }
        })
        .orderBy([
          r => _.chain(r.aspectRatio).split(':').first().toNumber().value(),
          r => _.chain(r.aspectRatio).split(':').last().toNumber().value(),
        ])
        .value()

      this.tabs = [...tabs.filter(r => r.aspectRatio === '0:3'), ...tabs.filter(r => r.aspectRatio !== '0:3')]
      this.allTabs = _.cloneDeep(this.tabs)
      if (this.groupedAssets.length === 0) this.activeTab = 'add'
      else if (this.activeTab === 'add') this.activeTab = _.result(this.tabs, '[0].aspectRatio')
      else if (!_.chain(this.allTabs).map('aspectRatio').includes(this.activeTab).value()) {
        this.activeTab = _.chain(this.allTabs).last().result('aspectRatio').value()
      }
    },
    changeSelectedAtTab({ selected, pixelSize }) {
      const formatedSelected = _.map(selected, r => {
        const asset = _.find(
          this.groupByAssets[`${pixelSize.width}_${pixelSize.height}`].concat(this.oldSelected),
          r2 => r2.originId === r.originId
        )
        return _.assign({}, asset, { pixelSize })
      })
      if (this.selected.length === 0) {
        this.selected = formatedSelected
        return
      }

      this.selected = _.chain(this.selected)
        .filter(r => `${r.pixelSize.width}×${r.pixelSize.height}` !== `${pixelSize.width}×${pixelSize.height}`)
        .concat(formatedSelected)
        .uniqBy('originId')
        .value()
    },

    changeSelected(isAll, isOnlyTab, isDeselectTab) {
      if (!isAll) {
        this.selected = []
        return
      }

      const reviewedTab = this.displayErrorAsset ? this.tabs : this.filteredAssetTab

      const keyBySelectedPixelSizes = isOnlyTab
        ? _.chain(reviewedTab)
            .filter(r => r.aspectRatio === this.activeTab)
            .map(r => _.filter(r.assets, r2 => r2.name === r.activeName))
            .flattenDeep()
            .map('pixelSize')
            .filter()
            .uniqBy(r => `${r.width}_${r.height}`)
            .keyBy(r => `${r.width}_${r.height}`)
            .value()
        : _.chain(reviewedTab)
            .map('assets')
            .flattenDeep()
            .map('pixelSize')
            .filter()
            .uniqBy(r => `${r.width}_${r.height}`)
            .keyBy(r => `${r.width}_${r.height}`)
            .value()
      const assetList = _.chain(this.allTabs)
        .map(r => _.map(r.assets, r2 => ({ aspectRatio: r.aspectRatio, pixelSize: r2.pixelSize })))
        .flattenDeep()
        .filter(r => keyBySelectedPixelSizes[`${r.pixelSize.width}_${r.pixelSize.height}`])
        .map(r => _.map(this.groupByAssets[`${r.pixelSize.width}_${r.pixelSize.height}`], r2 => _.assign({}, r, r2)))
        .flattenDeep()
        .map(r => _.pick(r, ['assetId', 'originId', 'pixelSize', 'regulationResult']))
        .filter(r => r.regulationResult.includes('Success'))
        .map(r => _.pick(r, ['assetId', 'originId', 'pixelSize']))
        .value()
      this.selected = isDeselectTab
        ? this.selected.filter(x => {
            return !_.includes(
              assetList.map(y => _.isEqual(y.assetId, x.assetId)),
              true
            )
          })
        : assetList
    },
    changeSelectedForCreativeRegist(selectedOriginIds) {
      const selectedAssets = _.map(selectedOriginIds, v => {
        const { pixelSize = {} } =
          _.find(this.groupedAssets, ({ assets }) => _.includes(_.map(assets, 'originId'), v)) || {}
        return { originId: v, pixelSize }
      })

      const filteredSelected = _.filter(this.selected, r => _.includes(selectedOriginIds, r.originId))
      const diff = _.differenceBy(selectedAssets, filteredSelected, 'originId')
      this.selected = _.concat(filteredSelected, diff)
    },
    initOldSelected(oldSelected) {
      this.oldSelected = oldSelected
    },
    downloadSelected() {
      const queryAssetId = _.map(this.selected, r => 'a=' + r.assetId).join('&')
      const queryPromotionId = `pId=` + this.$route.query.promotionIds
      const downloadURL = `${this.$api.ctxDomain}/asset/zip?${queryAssetId}&${queryPromotionId}`
      const a = document.createElement('a')
      a.setAttribute('href', downloadURL)
      a.click()
    },

    async reset() {
      if (!_.includes(['/creative-regist', '/creative-regist-only'], this.$route.path)) return

      if (!_.includes(['/creative-regist-only'], this.$route.path)) this.selected = []
      this.groupedAssets = []
      this.tabs = []
      this.activeTab = 'add'
      this.displayErrorAsset = true
      await this.$nextTick()
    },
    goToCreativeList() {
      const query = _.assign({}, this.$route.query, { originIds: _.map(this.selected, 'originId') })
      this.$router.push({ path: '/creative-list', query })
    },
    generateRandom() {
      return (Math.random() + 1).toString(36).substring(7)
    },
    async deleteSelected() {
      const ret = await this.$confirm('画像動画を削除しますか？', '確認', {
        customClass: 'delete-confirmation',
      }).catch(e => e)
      if (ret === 'cancel') return
      const originIds = _.map(this.selected, 'originId')
      this.loading = true
      const res = await this.$api.authFetch('/asset', {
        method: 'DELETE',
        query: { originId: originIds },
      })
      if (typeof res !== 'undefined') {
        if (res === 'クリエイティブに使用されているので削除できません。') {
          MessageBox.alert(res, 'エラー', {
            showCancelButton: true,
            distinguishCancelAndClose: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'クリエイティブを確認する',
            customClass: 'delete-confirmation',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm' || action === 'close') {
                done()
                this.loading = false
              } else {
                const query = _.assign({}, this.$route.query, {
                  originIds: _.map(this.selected, 'originId'),
                })
                const routeData = this.$router.resolve({ name: `creative-list`, query: query })
                window.open(`${window.location.origin}${routeData.href}`, '_blank')
              }
            },
          }).catch(action => {
            if (action == 'close') {
              this.loading = false
            }
          })
        } else {
          this.selected = []
          await this.getAssets()
        }
      }

      this.loading = false
    },
  },
  watch: {
    selectedPromotionIds() {
      if (this.tabs.length === 0) return
      this.getAssets(this.labelId ? { labelIds: [this.labelId] } : undefined)
    },
    selected() {
      if (!_.includes(['/creative-regist', '/wall-hitting', '/creative-regist-only'], this.$route.path)) return

      const selectedAssets = _.map(this.selected, r => {
        const tagetAssets = this.groupByAssets[`${r.pixelSize.width}_${r.pixelSize.height}`]
        const oldSelectedAsset = _.find(this.oldSelected, oldR => oldR.originId === r.originId)
        return oldSelectedAsset ? oldSelectedAsset : _.find(tagetAssets, r2 => r2.originId === r.originId)
      })
      this.oldSelected = selectedAssets
      this.$emit('change-selected-assets', selectedAssets)
    },
  },
  computed: {
    tabHeight() {
      let height = 0

      switch (this.$route.path) {
        case '/creative-regist':
          height =
            this.selected.length > 0
              ? this.innerHeight - 10 - 41 - 15 - 15 - 20 - 70 - 55
              : this.innerHeight - 10 - 41 - 15 - 15 - 20 - 70
          break
        case '/wall-hitting':
          height =
            this.selected.length > 0
              ? this.innerHeight - 10 - 41 - 15 - 15 - 20 - 70 - 55
              : this.innerHeight - 10 - 41 - 15 - 15 - 20 - 70
          break
        default:
          height = this.innerHeight - 60 - 41 - 10 - 72
          break
      }
      return this.tabs.length === 0 ? height : height - 41
    },
    drawerHeight() {
      return _.includes(['/creative-regist', '/wall-hitting'], this.$route.path)
        ? this.innerHeight - 55 - 16 - 16 - 70
        : this.innerHeight -
            60 -
            41 -
            41 -
            10 -
            20 -
            (this.isDrawerShow ? 45 + 185 : 0) -
            (this.isDisplayTypeDrawerShow ? 45 + 60 : 0)
    },
    // allAspectRatio () {
    //   return _.chain(this.assetSpecs).map('aspectRatio').uniq().value()
    // },
    groupByAssets() {
      return this.displayErrorAsset
        ? _.chain(this.groupedAssets)
            .keyBy(r => `${r.pixelSize.width}_${r.pixelSize.height}`)
            .mapValues('assets')
            .mapValues(gr => _.orderBy(gr, ['metadata.rawFileName'], ['asc']))
            .value()
        : _.chain(this.groupedAssets)
            .keyBy(r => `${r.pixelSize.width}_${r.pixelSize.height}`)
            .mapValues('assets')
            .mapValues(gr => _.filter(gr, r => r.regulationResult.includes('Success')))
            .mapValues(gr => _.orderBy(gr, ['metadata.rawFileName'], ['asc']))
            .value()
    },
    tagIdByTargets() {
      return _.chain(this.groupedAssets)
        .map('assets')
        .flattenDeep()
        .map(r => _.map(r.tags, r2 => _.assign({}, r2, { targetId: r.originId })))
        .flattenDeep()
        .groupBy('tagId')
        .map(gr => ({
          targetIds: _.map(gr, 'targetId'),
          tagId: _.first(gr).tagId,
        }))
        .value()
    },
    selectedTags() {
      const selectedTargetIdsAll = _.map(this.selected, 'originId')
      return _.chain(this.selected)
        .map(r =>
          _.chain(this.tagIdByTargets)
            .filter(r2 => _.includes(r2.targetIds, r.originId))
            .map(r2 => ({ targetId: r.originId, tagId: r2.tagId }))
            .value()
        )
        .flattenDeep()
        .groupBy('tagId')
        .mapValues(gr => {
          const selectedTargetIds = _.map(gr, 'targetId')
          return {
            selectedTargetIds,
            isAllSelected: selectedTargetIds.length === selectedTargetIdsAll.length,
          }
        })
        .value()
    },
    filteredGroupAsset() {
      const result = this.displayErrorAsset
        ? this.groupedAssets
        : _.chain(this.groupedAssets)
            .map(gr => ({
              assets: _.filter(gr.assets, r2 => r2.regulationResult.includes('Success')),
              aspectRatio: gr.aspectRatio,
              pixelSize: gr.pixelSize,
            }))
            .filter(r => r.assets.length !== 0)
            .value()
      return result
    },
    filteredAssetTab() {
      const result = this.displayErrorAsset
        ? this.tabs
        : _.chain(this.filteredGroupAsset)
            .map(({ aspectRatio, pixelSize }) => ({
              name: `${pixelSize.width}×${pixelSize.height}`,
              aspectRatio,
              pixelSize,
            }))
            .uniqBy(r => r.name + r.aspectRatio)
            .groupBy('aspectRatio')
            .map((gr, aspectRatio) => {
              const assets = _.orderBy(gr, ['pixelSize.width', 'pixelSize.height'])
              const activeName =
                this.tabs.length === 0
                  ? _.result(assets, '[0].name')
                  : _.chain(gr)
                      .find(r => r.aspectRatio === aspectRatio)
                      .thru(r => _.result(r, 'activeName') || _.result(assets, '[0].name'))
                      .value()
              return { aspectRatio, activeName, assets }
            })
            .orderBy([
              r => _.chain(r.aspectRatio).split(':').first().toNumber().value(),
              r => _.chain(r.aspectRatio).split(':').last().toNumber().value(),
            ])
            .value()
      const musicAtFirstRes = [
        ...result.filter(r => r.aspectRatio === '0:3'),
        ...result.filter(r => r.aspectRatio !== '0:3'),
      ]
      return musicAtFirstRes
    },
  },
}
</script>
