<template>
  <el-container id="app" v-loading="$route.path != '/approval-detail' ? loading : false">
    <el-header class="bg-header" v-if="$route.path != '/login'">
      <the-header
        :selected-promotion-ids="selectedPromotionIds"
        :advertisers="advertisers"
        :promotion-accounts="promotionAccountWithUID"
        :selected-promotion-account="selectedPromotionAccount"
        :promotion-info="promotionInfo"
        :url-history="urlHistory"
        @change-selected-promotion-ids="gr => (selectedPromotionIds = gr)"
        @change-selected-promotion-account="r => (selectedPromotionAccount = r)"
      />
    </el-header>
    <el-container id="app-body" :style="{ height: height + 'px' }" v-bind:class="{ mt60: $route.path !== '/login' }">
      <router-view
        :selected-promotion-ids="selectedPromotionIds"
        v-if="judgeView()"
        :ad-formats="adFormats"
        :whitelist="whitelist"
        :tags="tags"
        :tagTypes="tagTypes"
        :asset-specs="assetSpecs"
        :zipFileCount="zipFileCount"
        :youtubeUrlCount="youtubeUrlCount"
        :musicFileCount="musicFileCount"
        :selected-promotion-account="selectedPromotionAccount"
        :promotion-accounts="promotionAccountWithUID"
        :lastestModelVer="lastestModelVer"
        @change-selected-promotion-ids="gr => (selectedPromotionIds = gr)"
        @change-selected-promotion-account="r => (selectedPromotionAccount = r)"
        @reload="reload"
        @get-tags-and-tag-types="getTagsAndTagTypes"
      />
      <el-main v-else>プロモーションを指定してページを読み込んでください</el-main>
    </el-container>

    <tag-dialog
      ref="tagDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :tags="tags"
      :tagTypes="tagTypes"
      @reload="reload"
    />
  </el-container>
</template>

<style>
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

textarea {
  font-family: Arial, Helvetica, sans-serif;
}

/* for clarity css problem */
div[whitelist] {
  display: none;
}

.el-tooltip__popper.is-dark {
  background: #909399 !important;
  color: #fff;
}

.el-tooltip__popper .popper__arrow,
.el-tooltip__popper .popper__arrow::after {
  opacity: 50%;
}

.font-16 input {
  font-size: 16px !important;
}

.font-16-option {
  font-size: 16px !important;
  font-family: Arial, Helvetica, sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: contents;
}

.el-popover {
  min-width: 0 !important;
}
.el-dialog__header {
  position: sticky;
  top: 0px;
  z-index: 5;
  background: #004b6a;
  padding: 10px 20px 10px !important;
}
.el-dialog__title {
  color: #ffffff !important;
}
.el-dialog__footer {
  background: #004b6a;
  position: sticky;
  bottom: 0px;
  z-index: 5;
  padding: 10px 20px 10px !important;
}

.small-dialog {
  border-radius: 10px !important;
}
.small-dialog .el-dialog__footer {
  background-color: transparent;
}
.small-dialog .el-dialog__header {
  border-radius: 10px 10px 0px 0px;
}
.el-dialog__body {
  padding: 0 !important;
}

.el-message-box {
  border-radius: 10px !important;
  border: 0px !important;
}

.el-message-box__header {
  background-color: #004b6a;
}

.el-message-box__title {
  color: #ffffff !important;
}

.confirmation-box .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 10px;
  left: 4px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 6px;
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}

.confirmation-box .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 2px solid #909399 !important;
  border-radius: 2px;
  box-sizing: border-box;
  width: 20px !important;
  height: 20px !important;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

.confirmation-box .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  content: '';
  position: absolute;
  display: block;
  background-color: #fff;
  height: 2px;
  transform: scale(0.5);
  left: 0;
  right: 0;
  top: 7px;
}
.el-dialog__header .el-icon-close {
  color: #ffffff !important;
  font-size: 26px;
  font-weight: bold;
  background-color: #ff4444;
  border-radius: 5px;
}

.el-message-box__header .el-icon-close {
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: bold;
  background-color: #ff4444;
  border-radius: 4px;
}

.h-28 .el-textarea__inner {
  min-height: 28px !important;
  height: 28px !important;
  resize: none !important;
}

.el-dialog__headerbtn {
  top: 10px !important;
  right: 12px !important;
}
.el-message-box__headerbtn {
  top: 12px !important;
  right: 15px !important;
}

.h-152 .el-carousel__container {
  height: 150px !important;
}

/* el-select adformat box */
#el-select-ad-format-id {
  white-space: normal;
  max-width: 100%;
  text-overflow: ellipsis;
  width: 350px;
}

.v-switch-label.v-right {
  right: 10px !important;
  color: #000000 !important;
}
.v-switch-label.v-left {
  left: 15px !important;
}
.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: unset !important;
}
.switch-button {
  padding-left: 12px !important;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.m-1 {
  margin: 1rem;
}
.w-100 {
  width: 100%;
}
.text-center {
  text-align: center;
}
</style>

<style scoped>
.bg-header {
  background-image: url('https://tech-asset-storage-prod.s3.ap-northeast-1.amazonaws.com/frontend-asset/header_bg.png');
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  z-index: 3;
  top: 0px;
  width: 100%;
}
.mt60 {
  margin-top: 60px;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import TheHeader from '@/components/the-header'
import TagDialog from '@/components/tag-dialog'
import store from 'store'

export default {
  name: 'app',
  mixins: [util],
  components: { TheHeader, TagDialog },

  data: () => ({
    loading: false,
    init: false,
    selectedPromotionAccount: {},
    selectedPromotionIds: [],
    promotionAccounts: [],
    lastestModelVer: null,
    haveNewModel: false,
    adFormats: [],
    whitelist: [],
    advertisers: [],
    assetSpecs: [],
    zipFileCount: 0,
    youtubeUrlCount: 0,
    musicFileCount: 0,
    tags: [],
    tagTypes: [],
    selectedAccountId: null,
    height: window.innerHeight - 60,
    promotionInfo: [],
    urlHistory: null,
  }),
  async created() {
    this.init = true
    this.loading = true
    if ((await this.checkLogin(this.$route.path, this.$route.path)) === false) return
    this.loading = true

    window.App = this
    window.addEventListener('resize', () => {
      this.height = window.innerHeight - 60
    })

    if (this.$route.query.promotionIds && this.$route.query.promotionIds[0] !== undefined) {
      this.selectedPromotionIds = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds
        : [this.$route.query.promotionIds]
    }
    this.loading = true

    if (this.$route.path === '/wall-hitting') {
      this.promotionAccounts = await this.$api.authFetch('/promotionAccounts', {
        method: 'GET',
        headers: { Authorization: 'auth' },
      })
    }

    const [{ adFormats }, { advertisers }, { whitelist }] = await Promise.all([
      this.$api.authFetch('/adformats', { method: 'GET' }),
      this.$api.authFetch('/promotions', { method: 'GET' }),
      this.$api.authFetch('/promotion_whitelist', { method: 'GET' }),

      this.getAssetSpecs(),
      this.getTagsAndTagTypes(),
    ])

    this.whitelist = whitelist

    this.adFormats = adFormats
    this.promotionAccounts = this.promotionAccounts.map(r => {
      return _.assign({}, r, {
        combinedName: `${r.advertiserName}_${r.productName}_${r.platform}_${r.adAccountId}_${r.accountName}`,
      })
    })
    this.advertisers = _.map(advertisers, r => {
      const promotions = _.map(r.promotions, r2 => _.assign({}, r2, { id: r2.id.toString() }))
      return _.assign({}, r, { promotions })
    })

    this.promotionInfo = _.chain(this.advertisers)
      .map(r => {
        const advertiserId = r.id
        const advertiserName = r.name
        return _.map(r.promotions, promo => {
          return {
            advertiserId: advertiserId,
            advertiserName: advertiserName,
            promotionId: promo.id,
            promotionName: promo.name,
            combinedName: advertiserName + ' - ' + promo.name,
          }
        })
      })
      .flatten()
      .value()

    if (this.adFormats.length === 0) {
      this.$alert('登録されている広告フォーマットは0件です', '検索結果')
      return
    }

    const promoId = _.isArray(this.$route.query.promotionIds)
      ? this.$route.query.promotionIds
      : [this.$route.query.promotionIds]
    if (this.selectedIds !== promoId) {
      const promo = this.promotionInfo.find(x => x.promotionId === promoId[0])
      this.changePromotionHistory(promo)
      this.urlHistory = promo
      this.selectedIds = promoId
    }
    this.loading = false
    this.init = false
    if (this.$route.path == '/wall-hitting' && this.haveNewModel) {
      this.showNoticeNewOddAiModel()
    }
  },
  mounted() {
    this.sendLog()
  },
  methods: {
    showNoticeNewOddAiModel() {
      this.$alert(
        _.result(this.lastestModelVer, 'log_month') +
          '月' +
          _.result(this.lastestModelVer, 'log_date') +
          '日に予測モデルがv' +
          _.result(this.lastestModelVer, 'model_version') +
          'に更新されました。<br/>' +
          '更新日以前に予測した値と今日以降に予測した値が異なる場合がありますのでご注意ください。',
        'Odd-AI予測モデル更新',
        {
          confirmButtonText: '閉じる',
          dangerouslyUseHTMLString: true,
          callback: () => this.updateOddAiVersion(),
          customClass: 'oddAiVersion',
        }
      )

      return
    },
    changePromotionHistory(promotionsHistory) {
      this.promotionsHistory = _.chain([promotionsHistory].concat(store.get('promotionsHistory')))
        .uniqBy('promotionId')
        .filter(r => r !== undefined)
        .filter((_, i) => i < 5)
        .value()

      store.set('promotionsHistory', this.promotionsHistory)
    },
    async sendLog() {
      // const ret = await this.$api.authFetch('/logAccessUser', {
      //   method: 'POST',
      //   body: {user: await this.$gAuth.getEmail()},
      //   headers: {Authorization: 'auth'},
      //   domain: 'pd'
      // })
      // if (ret.error) console.error('log格納失敗')
    },
    async updateOddAiVersion() {
      const ret = await this.$api.authFetch('/odd_ai_version', {
        method: 'PUT',
        body: {
          email: await this.$gAuth.getEmail(),
          modelVersion: _.result(this.lastestModelVer, 'model_version'),
        },
      })
      this.haveNewModel = false
      if (ret.error) console.error('log odd_ai_version 格納失敗')
    },
    async reload() {
      this.$refs.tagDialog.loading = true
      this.loading = true
      await this.getTagsAndTagTypes()
      this.loading = false
      this.$refs.tagDialog.loading = false
    },
    async getTagsAndTagTypes() {
      if (this.selectedPromotionIds[0] === undefined) {
        this.tags = []
        this.tagTypes = []
        return
      }

      const option = { method: 'POST', body: { promotionIds: this.selectedPromotionIds } }
      const [{ tags }, { tagTypes }] = await Promise.all(
        _.map(['/tag/list', '/tag/type/list'], endPoint => this.$api.authFetch(endPoint, option))
      )

      this.tags = tags
      this.tagTypes = _.orderBy(tagTypes, ['tagTypeName'])
    },
    async getAssetSpecs() {
      if (this.selectedPromotionIds[0] === undefined) {
        this.assetSpecs = []
        return
      }

      const option = { method: 'POST', body: { promotionIds: this.selectedPromotionIds } }
      const result = await this.$api.authFetch('/asset/specs/list', option)
      this.assetSpecs = _.result(result, 'assetSpecs')
      this.zipFileCount = _.result(result, 'zipFileCount')
      this.youtubeUrlCount = _.result(result, 'youtubeUrlCount')
      this.musicFileCount = _.result(result, 'musicFileCount')

      if (this.$route.path !== '/wall-hitting' && this.$route.path !== '/tableau' && this.assetSpecs.length === 0) {
        this.$alert('登録されているサイズは0件です', '検索結果')
        return
      }
    },
    async checkLogin(to, from) {
      try {
        this.loading = true
        const isSignedIn = await this.$gAuth.isSignedIn()
        const checkEmployee = await this.$gAuth.isSub()
        if (!isSignedIn) this.$router.push({ path: '/login', query: this.$route.query })
        else if (checkEmployee && checkEmployee.isSub && checkEmployee.isSub === true) {
          if (_.includes(['/text-master', '/wall-hitting'], to))
            this.$router.push({ path: to, query: this.$route.query })
          else if (to === from) this.$router.push({ path: '/text-master', query: this.$route.query })
          else this.$router.push({ path: from, query: this.$route.query })
        }
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
        this.$message.error('もう一度ログインしてください')
        this.$router.push({ path: '/login', query: this.$route.query })
      }
    },
    judgeView() {
      if (this.$route.path === '/login') return true
      else if (!this.$route.query.promotionIds || !this.$route.query.promotionIds[0]) return false
      else return true
    },
    changeSelectedPromotionIds() {
      const selectedPromotionIds = this.selectedIds
      this.$emit('change-selected-promotion-ids', selectedPromotionIds)
    },
    generateRandom() {
      return (Math.random() + 1).toString(36)
    },
  },
  watch: {
    async selectedPromotionIds() {
      const query =
        this.selectedPromotionIds.length === 0
          ? _.omit(this.$route.query, ['promotionIds'])
          : _.assign({}, this.$route.query, { promotionIds: this.selectedPromotionIds })
      this.$router.push({ query })
      if (this.init) return
      this.loading = true
      await Promise.all([this.getAssetSpecs(), this.getTagsAndTagTypes()])
      this.loading = false

      const newQuery =
        this.$route.path === '/creative-list' ? this.$route.query : _.omit(this.$route.query, ['submissionRequestIds'])
      this.$router.push({ query: newQuery })
    },
    async '$route.path'(to, from) {
      // console.log(`change route: "%c${this.$route.path}%c" from "%c${from}%c" to "%c${to}%c" with query data: `, 'color: #409eff', 'color: white', 'color: tomato', 'color: white',  'color: green', 'color: white', this.$route.query);
      if (this.$route.path == '/wall-hitting' && this.haveNewModel) {
        this.showNoticeNewOddAiModel()
      }
      if (this.$route.path === '/wall-hitting' && this.promotionAccounts.length === 0) {
        this.loading = true
        const promotionAccounts = await this.$api.authFetch('/promotionAccounts', {
          method: 'GET',
          headers: { Authorization: 'auth' },
        })
        this.promotionAccounts = promotionAccounts.map(r => {
          return _.assign({}, r, {
            combinedName: `${r.advertiserName}_${r.productName}_${r.platform}_${r.adAccountId}_${r.accountName}`,
          })
        })
      }
      if (this.$route.path != '/login') {
        this.checkLogin(to, from)
      }
    },

    selectedIds() {
      if (this.isNotChangeSelectedIdsPromotionIds()) return
      this.changeSelectedPromotionIds()
    },

    selectedAccountId() {
      const selectedPromotionAccount = _.find(this.promotionAccounts, r => r.adAccountId === this.selectedAccountId)
      this.$emit('change-selected-promotion-account', selectedPromotionAccount)
      if (!_.isEmpty(selectedPromotionAccount))
        this.$emit('change-selected-promotion-ids', [selectedPromotionAccount.promotionId])
    },
  },
  computed: {
    promotions() {
      return _.chain(this.advertisers).map('promotions').flattenDeep().value()
    },
    selectedPromotions() {
      return _.chain(this.selectedPromotionIds)
        .map(id => _.find(this.promotions, r => r.id === id))
        .compact()
        .value()
    },
    promotionAccountWithUID() {
      return _.chain(this.promotionAccounts)
        .map(account => _.assign({}, account, { uid: `${account.adAccountId}_${account.platform}` }))
        .value()
    },
  },
}
</script>
