var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"font-mini",staticStyle:{"overflow":"visible","position":"relative","min-width":"230px","min-height":"300px"},attrs:{"id":"asset-item-box","shadow":"never","body-style":{ padding: '8px 16px', 'word-break': 'break-all' }}},[_c('el-row',{attrs:{"type":"flex","align":"center"}},[_c('div',{staticClass:"action-icon-list"},[(!_vm.isDisplay)?_c('el-checkbox',{attrs:{"label":_vm.asset.originId,"disabled":_vm.containError && !_vm.onAssetSelectDialog},on:{"change":function($event){return _vm.$emit('uncheck-asset', _vm.asset.assetId)}}}):_vm._e(),(![_vm.appRoute.CREATIVE_LIST, _vm.appRoute.CREATIVE_REGIST].includes(_vm.$route.path) && _vm.asset.assetType !== 'Url')?_c('div',{on:{"click":function($event){return _vm.$refs.renameDialog.show(_vm.asset.originId, _vm.asset.assetId, _vm.asset.metadata)}}},[_c('i',{staticClass:"fas fa-pen"})]):_vm._e(),(![_vm.appRoute.CREATIVE_LIST, _vm.appRoute.CREATIVE_REGIST].includes(_vm.$route.path))?_c('div',{on:{"click":function($event){return _vm.removeAsset(_vm.asset.originId)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()],1)]),_c('el-row',{staticClass:"mb-1 w-100",attrs:{"type":"flex","align":"center"}},[_c('el-col',[_c('div',{staticClass:"font-mini line-1"},[_vm._v(" "+_vm._s(_vm.asset.metadata.fileName)+" ")]),_c('div',{staticClass:"float-left font-mini line-1"},[_vm._v(" "+_vm._s(_vm.asset.metadata.rawFileName)+" ")])])],1),_c('el-row',[_c('el-row',{staticStyle:{"margin-bottom":"1vh"}},[_c('el-col',[(
            ![_vm.appRoute.CREATIVE_REGIST, _vm.appRoute.CREATIVE_REGIST_ONLY, _vm.appRoute.CREATIVE_LIST].includes(
              _vm.$route.path
            ) && _vm.isAssetMaster
          )?_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Select","size":"small"},on:{"change":function (status) { return _vm.changeStatus(status); }},model:{value:(_vm.asset.approvalStatus),callback:function ($$v) {_vm.$set(_vm.asset, "approvalStatus", $$v)},expression:"asset.approvalStatus"}},_vm._l((_vm.approvalStatusOptions),function(option,i){return _c('el-option',{key:i,attrs:{"label":option,"value":option}})}),1):_c('el-tag',{staticClass:"w-100 font-bold",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.asset.approvalStatus)+" ")])],1)],1)],1),_c('el-row',{staticClass:"mb-1"},[_c('image-movie-item',{staticClass:"mb-1",attrs:{"url":_vm.asset.url,"thumbnail-url":_vm.asset.thumbnailUrl,"type":_vm.asset.assetType}})],1),(_vm.asset.tags && _vm.asset.tags.length !== 0)?_c('el-row',{staticClass:"mt-1"},_vm._l((_vm.asset.tags),function(tag,i){return _c('span',{key:("tag_" + i)},[(tag.tagTypeName || tag.description)?_c('el-popover',{attrs:{"trigger":"hover"}},[(tag.tagTypeName)?_c('div',[_vm._v("カテゴリ: "+_vm._s(tag.tagTypeName))]):_vm._e(),(tag.description)?_c('div',[_vm._v("説明: "+_vm._s(tag.description))]):_vm._e(),_c('div',{staticClass:"font-bold mr-1 mb-1 tag tag-small",style:({ background: tag.color || '#909399', color: '#FFFFFF' }),attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(tag.tagValue)+" ")])]):_c('el-tag',{staticClass:"tag tag-small font-bold mr-1 mb-1",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(tag.tagValue)+" ")])],1)}),0):_vm._e(),_c('el-row',[_c('el-row',{key:"psdURL-title",staticClass:"mb-1"},[_c('i',{staticClass:"fas fa-copy"}),_vm._v(" BOX URL ")]),_c('el-row',{staticClass:"mb-1"},[_c('el-input',{staticClass:"w-100",attrs:{"size":"mini","rows":2},on:{"input":function (v) {
            _vm.debounceSavePsdUrl(v)
          }},model:{value:(_vm.psdURL),callback:function ($$v) {_vm.psdURL=$$v},expression:"psdURL"}})],1)],1),(_vm.containError)?_c('div',{staticStyle:{"position":"relative"}},[_c('i',{staticClass:"el-icon-warning error-mark"}),_c('span',{staticClass:"error-text"},[_vm._v("規定違反があります。")]),_c('el-button',{staticClass:"error-detail-btn",attrs:{"type":"danger","size":"mini"},on:{"click":function () {
          _vm.isShowErrorDetail = true
          this$1.$emit('change-id-open-error', _vm.asset.assetId)
        }}},[_vm._v("詳細確認")])],1):_vm._e(),_c('el-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowErrorDetail),expression:"isShowErrorDetail"}],staticClass:"error-detail",attrs:{"body-style":{ padding: '0' }}},[_c('div',{staticClass:"el-dialog__header d-flex error-detail__header"},[_c('p',{staticClass:"error-detail__header-title"},[_vm._v("規定違反の詳細")]),_c('el-button',{staticClass:"error-detail__header-closeBtn",on:{"click":function($event){_vm.isShowErrorDetail = false}}},[_c('i',{staticClass:"el-icon-close"})])],1),_c('div',{staticClass:"error-detail__content"},[_c('section',_vm._l((this.errorList),function(error,i){return _c('p',{key:("error_" + i),staticClass:"color-danger font-bold font-mini2"},[_vm._v(" ・"+_vm._s(error)+" ")])}),0)]),_c('div',{staticClass:"d-flex error-detail__controller"},[_c('el-button',{staticStyle:{"padding":"7px"},attrs:{"type":"primary","size":"mini"},on:{"click":_vm.copyError}},[_vm._v(" 規定違反内容を取得 ")])],1)]),_c('el-row',[_c('el-row',{staticClass:"mb-1"},[_c('el-divider',{staticClass:"m-0 d-flex w-100 divider"})],1),_c('el-row',{staticClass:"mb-1"},[_c('metadata-item',{attrs:{"version":_vm.asset.version,"metadata":_vm.asset.metadata,"errorList":_vm.errorTagList},on:{"open-version-dialog":function($event){return _vm.$refs.versionDialog.show(_vm.asset.originId)}}})],1)],1),_c('rename-dialog',{ref:"renameDialog",attrs:{"whitelist":_vm.whitelist}}),_c('version-dialog',{ref:"versionDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }