<template>
  <div id="normal-creatives" style="height: 100%">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="画像/動画" name="assets" :closable="false" style="padding: 10px 15px">
        <div v-if="chunkedAssets.length === 0" style="padding: 10px">素材はありません</div>

        <el-row v-else :gutter="15" class="mb-2" v-for="(groupAsset, i) in chunkedAssets" :key="`groupAsset_${i}`">
          <el-col :span="4" v-for="(asset, idx) in groupAsset" :key="`assset_${i}_${idx}`">
            <asset-group
              ref="assetGroup"
              class="mb-1"
              :key="asset.assetGroupId + i + idx"
              :adFormats="adFormats"
              :selected-asset="[asset]"
              :isApprovalRequestAssetGroup="true"
              :asset-regulate-error="assetRegulateError"
              @change-asset-group-addition-attach="files => handleAdditionAttachFile(files, asset.assetGroupId)"
              @change-asset-group-saved-attach="savedUrls => handleSavedAttachFile(savedUrls, asset.assetGroupId)"
              @change-creator-comment="comment => handleAssetGroupCreatorComment(comment, asset.assetGroupId)"
              @update-ver-asset="
                (file, assetId, skipCheckRule, originId, allOriginIds, labelId) =>
                  $emit('update-ver-asset', file, assetId, skipCheckRule, originId, allOriginIds, labelId)
              "
            />
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="テキスト" name="textSets" :closable="false" style="padding: 10px 15px">
        <div v-if="groupedTextSets.length === 0" style="padding: 10px">テキストセットはありません</div>
        <el-row v-else :gutter="15">
          <ad-format-tab
            :textSets="textSets"
            :ad-formats="adFormats"
            @update-text="(textSet, form, isEqual, version) => $emit('update-text', textSet, form, isEqual, version)"
            @change-creator-comment="
              (comment, textSetId, plaformId, version) =>
                $emit('change-creator-comment-text', comment, textSetId, plaformId, version)
            "
          />
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style scoped>
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.p-1 {
  padding: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
</style>

<style>
#normal-creatives .el-tabs {
  height: 100%;
}
</style>

<script>
import _ from 'lodash'
import AdFormatTab from '@/views/text-master/ad-format-tab-v2'
import AssetGroup from '@/views/approval-detail/components/ad-format-tab/creative-card/main-area/components/asset-group'

export default {
  name: 'uniq-creatives',
  components: { AssetGroup, AdFormatTab },
  props: {
    selected: { type: Array, default: () => [] },
    groupedCreatives: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    assetGroupData: { type: Array, default: () => [] },
    selectedCreativeIds: { type: Array, default: () => [] },
    assetRegulateError: { type: Array, default: () => [] },
  },
  data: () => ({
    activeName: 'assets',
    chunkedAssets: [],
    groupedTextSets: [],
    idOpenError: '',
  }),
  created() {
    window.UniqCreatives = this
    this.createAssetGroup()
    this.createTextSets()
  },
  methods: {
    changeIdOpenError(assetId) {
      this.idOpenError = assetId
    },
    createAssets() {
      if (_.isEmpty(this.groupByAssets)) return

      this.chunkedAssets = _.chain(this.selected)
        .map('adFormatId')
        .uniq()
        .map(adFormatId => this.groupByAssets[adFormatId])
        .flattenDeep()
        .filter()
        .chunk(6)
        .value()
    },
    reloadData() {
      const assetComponent = this.$refs['assetGroup']
      if (assetComponent) {
        assetComponent.forEach(x => {
          x.reloadData()
        })
      }
    },
    createTextSets() {
      if (_.isEmpty(this.groupByTextSets)) {
        this.groupedTextSets = []
        return
      }

      this.groupedTextSets = _.chain(this.selected)
        .map('adFormatId')
        .uniq()
        .map(adFormatId => this.groupByTextSets[adFormatId])
        .flattenDeep()
        .filter()
        .orderBy([r => r.platformId + r.adFormatId])
        .value()
    },

    sortAssetGroup(assetGroups) {
      const sortedAssetGroups = _.sortBy(assetGroups, obj =>
        _.join(
          _.map(obj.assets, 'metadata.fileName').map(a => a.toLocaleLowerCase()),
          ''
        )
      )
      return sortedAssetGroups
    },

    createAssetGroup() {
      const assetGroups = this.assetGroupsByLabelId

      if (_.isEmpty(assetGroups)) {
        this.chunkedAssets = []
        return
      }

      this.chunkedAssets = _.chain(this.sortAssetGroup(assetGroups))
        .map(assetGroup => {
          const labelId = assetGroup.labelId
          const assets = assetGroup.assets
          const approvalStatuses = _.map(assets, asset => asset.approvalStatus)
          // TODO: refactor to easier to read
          const approvalStatus = approvalStatuses.includes('破棄')
            ? '破棄'
            : approvalStatuses.includes('要修正')
            ? '要修正'
            : approvalStatuses.includes('監修中')
            ? '監修中'
            : approvalStatuses.includes('監修未提出')
            ? '監修未提出'
            : '承認'
          const assetGroupId = _.chain(assets)
            .map(asset => asset.assetId)
            .join('_')
            .value()

          const groupAssetData = _.find(this.assetGroupData, assetGroup => assetGroup.assetGroupId === assetGroupId)
          const creatorComment = (groupAssetData && groupAssetData.creatorComment) || ''
          const attachmentFile = (groupAssetData && groupAssetData.attachmentFile) || []

          return { labelId, assetGroupId, assets, approvalStatus, creatorComment, attachmentFile }
        })
        .chunk(6)
        .value()
    },
    handleAssetGroupCreatorComment(comment, assetGroupId) {
      if (!assetGroupId) return
      const assetGroupDataFilterResult = _.filter(
        this.assetGroupData,
        assetGroup => assetGroup.assetGroupId === assetGroupId
      )
      if (assetGroupDataFilterResult.length !== 0) {
        _.chain(assetGroupDataFilterResult)
          .uniq()
          .map(assetGroup => {
            assetGroup.creatorComment = comment
          })
          .value()
      } else {
        this.assetGroupData.push({
          assetGroupId: assetGroupId,
          creatorComment: comment,
          attachmentFile: [],
          additionFile: [],
        })
      }
      this.$emit('change-asset-group-payload', this.assetGroupData)
    },
    handleAdditionAttachFile(files, assetGroupId) {
      if (!assetGroupId) return
      const assetGroupDataFilterResult = _.filter(
        this.assetGroupData,
        assetGroup => assetGroup.assetGroupId === assetGroupId
      )
      if (assetGroupDataFilterResult.length !== 0) {
        _.chain(assetGroupDataFilterResult)
          .uniq()
          .map(assetGroup => (assetGroup.additionFile = _.map(files, file => file.name)))
          .value()
      } else {
        this.assetGroupData.push({
          assetGroupId: assetGroupId,
          creatorComment: '',
          attachmentFile: [],
          additionFile: _.map(files, file => file.name),
        })
      }
      this.$emit('change-asset-group-addition-attach', this.assetGroupData, files)
    },
    handleSavedAttachFile(savedUrls, assetGroupId) {
      if (!assetGroupId) return
      const assetGroupDataFilterResult = _.filter(
        this.assetGroupData,
        assetGroup => assetGroup.assetGroupId === assetGroupId
      )

      if (assetGroupDataFilterResult.length !== 0) {
        _.chain(assetGroupDataFilterResult)
          .uniq()
          .map(assetGroup => {
            assetGroup.attachmentFile = savedUrls
          })
          .value()
      } else {
        this.assetGroupData.push({
          assetGroupId: assetGroupId,
          creatorComment: '',
          attachmentFile: savedUrls,
          additionFile: [],
        })
      }
      this.$emit('change-asset-group-payload', this.assetGroupData)
    },
    log(data) {
      console.log(data)
    },
    getAssetGroupData(ids) {
      return this.$api
        .authFetch('/asset_group/list', {
          method: 'POST',
          body: ids,
        })
        .then(res => res)
        .catch(err => {
          console.log(err)
        })
    },
  },
  watch: {
    selected() {
      this.createAssets()
      this.createTextSets()
      this.createAssetGroup()
    },
    groupedCreatives() {
      this.createAssets()
      this.createTextSets()
      this.createAssetGroup()
    },
    assetGroupData() {
      this.createAssetGroup()
    },
  },
  computed: {
    groupByAssets() {
      const selectedCreativeIds = _.map(this.selected, 'creativeId')
      return _.chain(this.groupedCreatives)
        .map(({ platformId, creatives }) => {
          const filteredCreatives = _.filter(creatives, r => _.includes(selectedCreativeIds, r.creativeId))
          return _.map(filteredCreatives, creative => {
            const adFormatId = _.result(creative, 'adFormat.id')
            return _.chain(creative.labeledAssets)
              .map(({ labelId, assets }) =>
                _.map(assets, asset => {
                  const tags = _.filter(creative.combinedTags, r => _.includes(r.originIds, asset.originId))
                  return _.assign({}, asset, {
                    labelId,
                    platformId,
                    adFormatId,
                    tags,
                    creativeId: creative.creativeId,
                  })
                })
              )
              .flattenDeep()
              .value()
          })
        })
        .flattenDeep()
        .uniqBy('originId')
        .orderBy('originId')
        .groupBy('adFormatId')
        .value()
    },
    groupByTextSets() {
      const selectedCreativeIds = _.map(this.selected, 'creativeId')
      return _.chain(this.groupedCreatives)
        .map(({ platformId, creatives }) =>
          _.chain(creatives)
            .filter(r => _.includes(selectedCreativeIds, r.creativeId))
            .map(creative => {
              if (!creative.textSet) return

              const { id: adFormatId, name: adFormatName } = _.result(creative, 'adFormat')
              const tags = _.filter(creative.combinedTags, r => r.textSetId === creative.textSet.textSetId)
              return _.assign({}, creative.textSet, { platformId, adFormatId, adFormatName, tags })
            })
            .filter()
            .value()
        )
        .flattenDeep()
        .uniqBy('textSetId')
        .orderBy('textSetId')
        .groupBy('adFormatId')
        .mapValues(gr => {
          const { platformId, adFormatId, adFormatName } = _.first(gr)
          return { platformId, adFormatId, adFormatName, textSets: gr }
        })
        .value()
    },
    assetGroupsByLabelId() {
      const selectedCreativeIds = _.map(this.selected, 'creativeId')
      const result = _.chain(this.groupedCreatives)
        .map(({ creatives }) => {
          const filteredCreatives = _.filter(creatives, r => _.includes(selectedCreativeIds, r.creativeId)) // get select creatives
          return _.map(filteredCreatives, creative => creative.labeledAssets) // return list of labeled assets
        })
        .flattenDeep()
        .reduce((uniqueAssetList, checkingAsset) => {
          if (
            _.filter(uniqueAssetList, uniqueAsset => _.isEqual(uniqueAsset.assets, checkingAsset.assets)).length === 0
          )
            uniqueAssetList.push(checkingAsset)
          return uniqueAssetList
        }, [])
        .value()

      return result
    },
    textSets() {
      const distinctTextValues = []
      const selectedCreativeIds = _.map(this.selected, 'creativeId')
      const a = _.chain(this.groupedCreatives)
        .map(({ platformId, creatives, plaformName }) =>
          _.chain(creatives)
            .filter(r => _.includes(selectedCreativeIds, r.creativeId))
            .map(creative => {
              if (!creative.textSet) return

              const { id: adFormatId, name: adFormatName } = _.result(creative, 'adFormat')
              const tags = _.filter(creative.combinedTags, r => r.textSetId === creative.textSet.textSetId)
              return _.assign({}, creative.textSet, {
                platformId,
                platformName: plaformName,
                adFormatId,
                adFormatName,
                tags,
              })
            })
            .filter()
            .value()
        )
        .flattenDeep()
        .uniqBy(textSet => `${textSet.textSetId}_${textSet.version}`)
        .filter(x => x.textSetId)
        .orderBy(['textSetId', 'version'], ['asc', 'desc'])
        .map(gr => {
          const labeledTexts = gr.labeledTexts.map(labeledText => {
            return {
              ...labeledText,
              textValues: labeledText.textValues.map(textValue => {
                if (distinctTextValues.includes(textValue)) {
                  return {
                    value: textValue,
                    isDuplicate: true,
                  }
                } else {
                  distinctTextValues.push(textValue)
                  return {
                    value: textValue,
                    isDuplicate: false,
                  }
                }
              }),
            }
          })
          return {
            ...gr,
            labeledTexts,
          }
        })
        .sortBy([t => t.platformName.toLocaleLowerCase(), t => t.adFormatName.toLocaleLowerCase()])
        .groupBy('adFormatId')
        .value()

      return a
    },
  },
}
</script>
