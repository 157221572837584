<template>
  <el-col class="wrap">
    <div id="asset-group" ref="assetGroup" class="font-mini card" v-if="isApprovalRequestAssetGroup">
      <section class="approval-asset-group">
        <div class="items-title" :style="{ backgroundColor: this.backgroundColor, color: this.textColor }">
          <span v-text="selectedAsset[0].approvalStatus"></span>
        </div>
        <div class="thumb" ref="assetGroupThumbnail">
          <div>
            <section>
              <div
                :style="{
                  display: 'flex',
                  'justify-content': 'center',
                  'align-items': 'center',
                  height: assetThumbMaxHeight + 'px',
                  padding: '8px',
                }"
              >
                <img
                  v-if="activeAsset.assetType && activeAsset.assetType === 'Zip'"
                  :src="require(`@/api/data/imageMovieMaster/zip.jpg`)"
                  :height="assetThumbMaxHeight + 'px'"
                  :style="{ padding: '8px' }"
                  alt="zip"
                />
                <div
                  v-else-if="activeAsset.assetType && activeAsset.assetType === 'Music'"
                  :style="{
                    padding: '8px',
                    height: assetThumbMaxHeight + 'px',
                    width: '100%',
                    display: 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                    'align-items': 'center',
                    position: 'relative',
                  }"
                >
                  <img
                    alt="music"
                    :src="require(`@/assets/audio.png`)"
                    style="height: 100%; width: fit-content"
                    preload="metadata"
                    @click.prevent
                  />
                  <audio
                    style="position: absolute; bottom: 10px; width: 100%; height: 40px"
                    :src="activeAsset.url"
                    controls
                    controlslist="nofullscreen nodownload"
                  ></audio>
                </div>
                <iframe
                  v-else-if="activeAsset.assetType && activeAsset.assetType === 'Url'"
                  :src="activeAsset.url"
                  alt="youtube"
                  :width="assetThumbMaxHeight + 'px'"
                  :style="{}"
                />
                <video
                  v-else-if="activeAsset.assetType && activeAsset.assetType === 'Video'"
                  :src="activeAsset.url"
                  controls
                  controlslist="nofullscreen nodownload"
                  @click.prevent
                  :style="{
                    width: assetThumbMaxHeight + 'px',
                    'max-height': assetThumbMaxHeight + 'px',
                  }"
                />
                <img
                  v-else
                  :src="activeAsset.url"
                  alt="asset thumbnail"
                  :style="{ 'max-height': assetThumbMaxHeight + 'px', padding: '8px' }"
                />
              </div>
              <slick
                v-if="selectedAsset[0].assets.length !== 1"
                class="slider slider-thumbnail"
                ref="slickAssetGroupThumb"
                :id="selectedAsset.assetGroupId"
                :options="slickOptions"
                :style="{ minHeight: slickThumbHeight + 'px' }"
                @init="handleInit"
              >
                <div
                  v-for="(asset, index) in selectedAsset[0].assets"
                  :key="`thumbnail_${index}`"
                  :class="index === activeAssetIndex ? 'active-item thumb-items' : 'thumb-items'"
                  :style="{
                    height: slickThumbHeight + 'px',
                    width: slickThumbHeight + 'px',
                    display: 'flex',
                    'justify-content': 'center',
                    'align-items': 'center',
                  }"
                  @click="() => changeCurrentThumb(index)"
                >
                  <div style="width: 100%; height: 100%">
                    <img
                      v-if="asset.assetType && asset.assetType === 'Zip'"
                      :src="require(`@/api/data/imageMovieMaster/zip.jpg`)"
                      alt="zip"
                      :style="{
                        'max-height': slickThumbHeight - 4 + 'px',
                        'max-width': slickThumbHeight - 4 + 'px',
                      }"
                    />
                    <img
                      v-else-if="asset.assetType && asset.assetType === 'Music'"
                      :src="require(`@/assets/audio.png`)"
                      alt="music"
                      :style="{
                        'max-height': slickThumbHeight - 4 + 'px',
                        'max-width': slickThumbHeight - 4 + 'px',
                      }"
                    />
                    <img
                      v-else-if="asset.assetType && asset.assetType === 'Url'"
                      :src="require(`@/assets/youtube.jpeg`)"
                      alt="youtube"
                      :style="{
                        height: slickThumbHeight - 4 + 'px',
                        width: slickThumbHeight - 4 + 'px',
                        'pointer-events': 'none',
                      }"
                    />
                    <video
                      v-else-if="asset.assetType && asset.assetType === 'Video'"
                      :src="asset.url"
                      @click.prevent
                      alt="thumbnail-asset-video"
                      :style="{
                        'max-height': slickThumbHeight - 4 + 'px',
                        'max-width': slickThumbHeight - 4 + 'px',
                      }"
                    />
                    <img
                      v-else
                      :src="asset.url"
                      alt="thumbnail-asset-img"
                      :style="{
                        'max-height': slickThumbHeight - 4 + 'px',
                        'max-width': slickThumbHeight - 4 + 'px',
                      }"
                    />
                  </div>
                </div>
              </slick>
            </section>
          </div>
        </div>
        <el-tooltip
          class="item"
          effect="light"
          :content="activeAsset.metadata.fileName"
          placement="top-start"
          :visible-arrow="false"
        >
          <div class="thumb-name">
            <span class="thumb-ver" v-text="'v' + activeAsset.version"></span>
            <span class="name" v-text="activeAsset.metadata.fileName"></span>
          </div>
        </el-tooltip>
        <div class="form-group mb-3 flex">
          <label class="form-label"><i class="fas fa-comments"></i> 制作意図</label>
          <textarea class="form-control" v-model="creatorComment"></textarea>
        </div>
        <section class="attach-file-input">
          <input
            type="file"
            class=""
            ref="attachAsset"
            hidden
            multiple
            accept="image/png, image/jpeg"
            @change="changeAsset"
          />
          <button class="btn btn-sm btn-outline-primary" @click="addAttachAsset()" :disabled="disableAttachAsset">
            ファイルを添付
          </button>
        </section>
        <section class="attach-container" ref="attachAssets" :id="`attach_${selectedAsset.assetGroupId}`">
          <div
            class="attach-item"
            v-for="(attachAssetUrl, index) in attachAssetUrls"
            :key="`attach_${selectedAsset.assetGroupId}_${index}`"
            :style="{
              width: '20%',
              'aspect-ratio': '1/1',
              position: 'relative',
              'align-items': 'center',
            }"
          >
            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                background-color: #060630;
                cursor: pointer;
              "
              @click="
                e => {
                  openItemDialog(attachAssetUrl.url, `create_attach_image_${attachAssetUrl.url}_${index}`)
                }
              "
            >
              <img
                :src="attachAssetUrl.url"
                :id="`create_attach_image_${attachAssetUrl.url}_${index}`"
                style="max-height: 100%; max-width: 100%; margin: auto"
              />
            </div>

            <button
              class="remove-attach-btn"
              @click="() => removeAttachFile(index)"
              :style="{
                width: attachAssetWidth * 0.3 + 'px',
                height: attachAssetWidth * 0.3 + 'px',
              }"
            >
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
        </section>
      </section>
    </div>

    <div id="asset-group" ref="assetGroup" class="font-mini" v-else-if="!isPredictScreen">
      <div v-if="viewSelectedAsset.selectedLength === 1" class="w-100 word-break">
        <el-card
          style="border: solid 1px #dcdfe6 !important"
          id="asset-item"
          shadow="never"
          :body-style="{ padding: '8px', 'word-break': 'break-all' }"
          class="font-mini"
        >
          <el-row>
            <span @click="$emit('remove-selected')">
              <i
                class="badge icon-red relative float-right font-middle fa-solid fa-square-xmark fa-lg lh-0 move-to-top-right"
              />
            </span>
            <div :style="{ position: 'relative' }">
              <el-tag type="info" size="mini" class="c-black" :style="{}">{{
                viewSelectedAsset.first.labelName.trim()
              }}</el-tag>
            </div>
            <div v-if="isDisplayFileName">
              <div class="float-left w-100 scroll-4 hidden-scroll">
                <div class="font-mini-2 lh-1">
                  {{ viewSelectedAsset.first.metadata.fileName }}
                </div>

                <div class="font-mini-2 lh-1">
                  {{ viewSelectedAsset.first.metadata.rawFileName }}
                </div>
              </div>
            </div>
            <div v-else></div>

            <div
              class="h-152"
              v-if="isDisplayAssets"
              @click="$emit('asset-select-dialog-show', viewSelectedAsset.labeledAssets)"
            >
              <image-movie-item
                class="float-left w-100 h-100"
                :url="viewSelectedAsset.first.url"
                :thumbnail-url="viewSelectedAsset.first.thumbnailUrl"
                :type="viewSelectedAsset.first.assetType"
                :no-preview="true"
                parentComponent="creative-regist"
                :isMask="true"
              />
            </div>
            <div v-else></div>
          </el-row>
          <el-row>
            <div v-if="assetErrors.length !== 0" class="vertical-align text-center h-10">
              <div class="error-text text-center" style="font-size: 10px">規定違反があります。</div>
              <el-button
                class="w-50 plr-5 error-detail-btn"
                type="danger"
                size="mini"
                @click="$emit('asset-select-dialog-show', viewSelectedAsset.labeledAssets)"
              >
                詳細確認
              </el-button>
            </div>

            <div v-else class="h-10"></div>
          </el-row>
        </el-card>
      </div>

      <div v-else class="float-left w-100 word-break" :class="{ 'mb-1': assetErrors.length !== 0 }">
        <el-card
          style="border: solid 1px #dcdfe6 !important"
          id="asset-item"
          shadow="never"
          :body-style="{ padding: '8px', 'word-break': 'break-all' }"
          class="font-mini"
        >
          <el-row>
            <el-row class="float-right w-100">
              <span @click="$emit('remove-selected')">
                <i
                  class="badge icon-red relative float-right font-middle fa-solid fa-square-xmark fa-lg lh-0 move-to-top-right"
                />
              </span>

              <div :style="{ position: 'relative' }">
                <el-tag type="info" size="mini" class="c-black" :style="{}">{{
                  viewSelectedAsset.assets[viewActiveIndex].labelName.trim()
                }}</el-tag>
              </div>
            </el-row>

            <div class="font-mini-2 line-1" v-if="isDisplayFileName">
              <div class="w-100 scroll-4 hidden-scroll">
                <div class="font-mini-2 line-1">
                  {{ viewSelectedAsset.assets[viewActiveIndex].metadata.fileName }}
                </div>
                <div class="font-mini-2 line-1">
                  {{ viewSelectedAsset.assets[viewActiveIndex].metadata.rawFileName }}
                </div>
              </div>
            </div>
            <div v-else></div>
            <div class="h-152" v-if="isDisplayAssets">
              <el-carousel
                indicator-position="none"
                :autoplay="false"
                :loop="false"
                :height="assetWidth + 'px'"
                class="h-152"
                @change="index => changeActiveIndex(index)"
              >
                <el-carousel-item v-for="({ url, thumbnailUrl, assetType }, k) in viewSelectedAsset.assets" :key="k">
                  <div class="w-100 h-100" @click="$emit('asset-select-dialog-show', viewSelectedAsset.labeledAssets)">
                    <image-movie-item
                      class="w-100 h-100"
                      :url="url"
                      :thumbnail-url="thumbnailUrl"
                      :type="assetType"
                      parentComponent="creative-regist"
                      :no-preview="true"
                      :isMask="true"
                    />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div v-else></div>
          </el-row>
          <el-row>
            <div v-if="assetErrors.length !== 0" class="vertical-align text-center h-10">
              <div class="error-text text-center">規定違反があります。</div>
              <el-button
                class="w-50 plr-5 error-detail-btn"
                type="danger"
                size="mini"
                @click="$emit('asset-select-dialog-show', viewSelectedAsset.labeledAssets)"
              >
                詳細確認
              </el-button>
            </div>
            <div v-else class="h-10"></div>
          </el-row>
        </el-card>
      </div>
    </div>
    <div ref="assetGroup" class="font-mini" v-else>
      <div
        v-if="viewSelectedAsset.selectedLength === 1"
        class="w-100 word-break"
        @click="$emit('asset-select-dialog-show', viewSelectedAsset.labeledAssets)"
      >
        <div v-if="isDisplayFileName">
          <div class="float-left w-100">
            <div class="font-mini-2 lh-1">
              {{ viewSelectedAsset.first.metadata.fileName }}
            </div>

            <div class="font-mini-2 lh-1">
              {{ viewSelectedAsset.first.metadata.rawFileName }}
            </div>
          </div>
        </div>
        <div v-else></div>

        <div v-if="isDisplayAssets">
          <image-movie-item
            class="float-left w-100 h-100"
            :url="viewSelectedAsset.first.url"
            :thumbnail-url="viewSelectedAsset.first.thumbnailUrl"
            :type="viewSelectedAsset.first.assetType"
            :no-preview="true"
            parentComponent="creative-regist"
            :isMask="true"
          />
        </div>
        <div v-else></div>
      </div>

      <div v-else class="float-left w-100 word-break" :class="{ 'mb-1': assetErrors.length !== 0 }">
        <div class="font-mini-2 line-1" v-if="isDisplayFileName">
          {{ viewSelectedAsset.assets[activeIndex].metadata.rawFileName }}, その他
          {{ viewSelectedAsset.selectedLength === 0 ? 0 : viewSelectedAsset.selectedLength - 1 }}個
        </div>
        <div v-else></div>

        <div v-if="isDisplayAssets">
          <el-carousel
            indicator-position="none"
            :autoplay="false"
            :loop="false"
            :height="assetWidth + 'px'"
            @change="index => $emit('change-active-index', index)"
          >
            <el-carousel-item
              v-for="({ labelName, url, thumbnailUrl, assetType }, k) in viewSelectedAsset.assets"
              :key="k"
            >
              <div>{{ labelName }}</div>
              <div @click="$emit('asset-select-dialog-show', viewSelectedAsset.labeledAssets)">
                <image-movie-item
                  class="w-100 h-100"
                  :url="url"
                  :thumbnail-url="thumbnailUrl"
                  :type="assetType"
                  parentComponent="creative-regist"
                  :no-preview="true"
                  :isMask="true"
                />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-else></div>
      </div>

      <div v-if="!isDisplay" class="relative">
        <span class="badge" @click="$emit('remove-selected')">
          <i class="fas fa-times-circle icon-red" />
        </span>
      </div>

      <div v-if="assetErrors.length !== 0" class="text-center">
        <el-button
          class="w-100"
          type="danger"
          size="small"
          @click="$emit('asset-select-dialog-show', viewSelectedAsset.labeledAssets)"
        >
          エラー
        </el-button>
      </div>
    </div>
    <item-dialog ref="itemDialog" :url="previewingImage" />
  </el-col>
</template>

<style scoped>
.is-asset-error {
  border: medium solid #f56c6c;
}
.wrap {
  border: 0;
  padding: 0 !important;
}
.plr-5 {
  padding: 7px 5px;
}
.move-to-top-right {
  position: absolute;
  z-index: 2;
  left: 94%;
  transform: translateY(-30%);
}
.max-label-slot {
  visibility: hidden;
}
.label-box {
  width: 85%;
  height: 36px;
  background-color: #f4f4f5;
  text-align: center;
  border-color: #e9e9eb;
  border-radius: 5px;
  color: #909399;
  display: table;
}
.label-text {
  display: table-cell;
  vertical-align: middle;
}
.vertical-align {
  display: flex;
  align-content: center;
  text-align: center;
}
.h-10 {
  height: 27px;
  margin-top: 3px;
}
.error-text {
  color: #f56c6c;
  font-size: 10px;
  align-items: center;
  margin: auto;
}
.scroll-4 {
  overflow-y: scroll;
  height: 70px;
}
.icon-red {
  color: #f56c6c;
}

.line-1 {
  line-height: 18px;
}

.font-mini-2 {
  font-size: 10px;
}

.font-mini {
  font-size: 12px;
  cursor: default;
}

.word-break {
  word-wrap: break-word;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.8rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.lh-1 {
  line-height: 18px;
}

.h-152 {
  height: 152px !important;
}

.h-152 .el-carousel__container {
  height: 150px !important;
}
/* .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 2;
} */
.badge {
  cursor: pointer;
}

.absolute-r-50 {
  position: absolute;
  left: 50%;
  z-index: 1;
}

.bg-dark {
  background: #d4d4d4;
}

/* .relative {
    position: relative
} */

.absolute-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-center {
  text-align: center;
}

.w-50 {
  width: 60%;
}

.c-black {
  white-space: break-spaces;
  height: fit-content;
  z-index: 1;
  cursor: default;
}

.form-label {
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 600;
  color: #060630;
}

.form-control {
  display: block;
  font-size: 12px;
  padding: 5px 8px;
  color: #060630;
  font-weight: 500;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow-y: scroll;
}

#asset-group .approval-asset-group textarea.form-control {
  overflow: auto;
  resize: vertical;
  min-height: 64px;
}

.flex {
  display: flex;
  flex-direction: column;
}

.btn {
  border-radius: 4px;
  font-size: 12px;
  width: fit-content;
  height: 30px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  /* background-color: transparent; */
  border: 1px solid #ccc;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}
</style>

<style>
.card {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  padding: 12px;
}

.items-title {
  background-color: #f4f4f5;
  padding: 4px 8px;
  font-size: 12px;
  text-align: center;
  margin-bottom: 8px;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 1px;
  border: 1px solid #d3d4d6 !important;
}

.thumb {
  margin-bottom: 8px;
  text-align: center;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
}

.thumb > img {
  max-width: 100%;
  height: 100%;
  height: auto;
}

.thumb-name {
  position: relative;
  flex-flow: wrap;
  color: #4692b5;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.thumb-name .name {
  font-size: 11px;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thumb-name .name-tooltip {
  display: none;
  z-index: 9;
  padding: 2px 4px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #ddd;
}

.thumb-name:hover > .name-tooltip {
  display: block;
}

.thumb-ver {
  display: inline-block;
  font-size: 11px;
  background: rgba(32, 32, 32, 0.8);
  padding: 1px 2px 1px 3px;
  margin-right: 4px;
  color: #fff;
  border-radius: 2px;
  /* &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: #4692b5;
        margin-right: 8px;
        border-radius: 50%;
    } */
}

.btn-outline-primary {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff !important;
  height: fit-content !important;
}

.btn-outline-primary:hover:not(:disabled) {
  opacity: 0.8;
}

.btn-outline-primary:disabled {
  background-color: #66666638;
  border-color: #66666638 !important;
  cursor: no-drop;
}

#asset-group .slick-track {
  display: flex;
}

#asset-group .slick-list.draggable {
  width: -webkit-fill-available !important;
}

#asset-group .slick-slide {
  width: fit-content !important;
}

#asset-group .slick-slide > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#asset-group .slick-prev,
#asset-group .slick-next {
  cursor: pointer;
  position: absolute;
  border: none;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: none;
}

#asset-group .slick-prev {
  left: 0;
  transform: translate(-16px, -50%);
}

#asset-group .slick-next {
  right: 0;
  transform: translate(16px, -50%);
}

.thumb-items:hover {
  cursor: pointer;
}

.thumb-items img {
  padding: 2px !important;
}

.active-item {
  border: 1px #ff4444 solid;
}

#asset-group .slick-arrow {
  height: 24px !important;
  width: 24px !important;
  background-color: transparent;
  border: none;
  z-index: 5;
  color: #000;
  font-size: 18px;
  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    color: #ddd;
    cursor: no-drop;
  }
}

#asset-group .attach-container {
  display: flex;
}

#asset-group .attach-container .attach-item {
  margin-left: 2px;
  display: flex;
  border: 1px solid transparent;
}

#asset-group .attach-container .attach-item img {
  object-fit: cover;
}

#asset-group .attach-container .attach-item .remove-attach-btn {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #ff4444;
  border: none;
  color: #fff;
  border-radius: 4px;
  font-size: 8px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 5;
  cursor: pointer;
}

#asset-group .attach-container .attach-item:hover {
  border: 1px solid #ff4444;
}

#asset-group .attach-container .attach-item:hover > .remove-attach-btn {
  display: flex;
}

#asset-group .attach-file-input {
  display: flex;
  flex-direction: column;
  padding: 0 4px 8px;
}

#asset-group .attach-file-input__error {
  color: #f56c6c;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 4px;
}
</style>

<script>
import _ from 'lodash'
import Slick from 'vue-slick'
import ImageMovieItem from '@/components/image-movie-item'
import ItemDialog from '@/components/image-movie-item/components/item-dialog'

export default {
  name: 'asset-group',
  components: {
    ImageMovieItem,
    ItemDialog,
    Slick,
  },
  props: {
    selectedAsset: {
      type: Array,
      default: () => [],
    },
    isDisplay: {
      type: Boolean,
      default: () => false,
    },
    assetErrors: {
      type: Array,
      default: () => [],
    },
    isDisplayFileName: {
      type: Boolean,
      default: () => true,
    },
    isDisplayAssets: {
      type: Boolean,
      default: () => true,
    },
    activeIndex: {
      type: Number,
      default: () => 0,
    },
    maxLabel: {
      type: String,
      default: () => null,
    },
    isPredictScreen: {
      type: Boolean,
      default: () => false,
    },
    isApprovalRequestAssetGroup: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    assetWidth: 150,
    assetThumbMaxHeight: 180,
    carouselActiveIndex: 0,
    activeAssetIndex: 0,
    slickOptions: {
      prevArrow:
        "<button class='slick-prev slick-arrow' aria-label='Previous' type='button'><i class='fa-solid fa-caret-left'></i></button>",
      nextArrow:
        "<button class='slick-next slick-arrow' aria-label='Next' type='button'><i class='fa-solid fa-caret-right'></i></button>",
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
    },
    additionalAttachAssets: [],
    attachAssetS3Url: [],
    creatorComment: '',
    previewingImage: '',
  }),
  created() {
    window.AssetGroup = this
    window.addEventListener('resize', () => {
      this.changeAssetHeight()
      this.changeAssetThumbMaxHeight()
    })
  },
  async mounted() {
    await this.$nextTick()
    this.changeAssetHeight()
    if (this.isApprovalRequestAssetGroup) {
      this.changeAssetThumbMaxHeight()
      this.initAssetGroupData()
    }
  },
  methods: {
    changeAssetHeight() {
      if (!this.$refs.assetGroup) return
      this.assetWidth = this.$refs.assetGroup.clientWidth + 18
    },
    changeAssetThumbMaxHeight() {
      if (!this.$refs.assetGroup) return
      const maxHeight =
        this.$refs.assetGroupThumbnail.clientWidth || // calculate height of slick thumb if not yet rendered
        (window.innerWidth - 15 * 5 - 15 * 2 - 16 * 2 - 15 * 2) / 6 - 12 * 2 // innerWidth - gutter size - paddingX - marginX - 2 scrollbar width / 6 - paddingX of each card
      this.assetThumbMaxHeight = this.selectedAsset[0].assets.length !== 1 ? (maxHeight * 4) / 5 - 16 : maxHeight
    },
    log(mess, data) {
      console.log(mess, data)
    },
    changeActiveIndex(index) {
      if (this.$route.path === '/creative-regist') {
        this.carouselActiveIndex = index
      } else {
        this.$emit('change-active-index', index)
      }
    },
    changeAsset(e) {
      const error = '5枚以上選択できません。'

      const fileList = e.target.files

      const newFileList = _.chain(this.additionalAttachAssets)
        .concat(Array.from(fileList))
        .flattenDeep()
        .uniqBy('name')
        .value()
      if (newFileList.length + this.attachAssetS3Url.length > 5) {
        this.$message.error(error)
      } else {
        this.additionalAttachAssets = newFileList
      }
    },
    removeAttachFile(index) {
      const removingAttachAsset = this.attachAssetUrls[index]
      if ((removingAttachAsset.name && removingAttachAsset.size) !== undefined) {
        const additionalAttachIndex = _.findIndex(
          this.additionalAttachAssets,
          asset => asset.name === removingAttachAsset.name && asset.size === removingAttachAsset.size
        )
        this.attachAssetUrls.splice(index, 1)
        this.additionalAttachAssets.splice(additionalAttachIndex, 1)
      } else {
        const urlIndex = _.findIndex(this.attachAssetS3Url, url => url === removingAttachAsset.url)
        this.attachAssetUrls.splice(index, 1)
        this.attachAssetS3Url.splice(urlIndex, 1)
      }
    },
    changeCurrentThumb(index) {
      this.activeAssetIndex = index
    },
    addAttachAsset() {
      const fileInputElement = this.$refs.attachAsset
      if (!this.disableAttachAsset) {
        fileInputElement.click()
      }
    },
    next(nextBtn, prevBtn) {
      if (this.activeAssetIndex === 0) prevBtn.show()
      if (this.activeAssetIndex < this.selectedAsset[0].assets.length - 1) this.activeAssetIndex++
      if (this.activeAssetIndex === this.selectedAsset[0].assets.length - 1) nextBtn.hide()
    },
    prev(nextBtn, prevBtn) {
      if (this.activeAssetIndex === this.selectedAsset[0].assets.length - 1) nextBtn.show()
      if (this.activeAssetIndex > 0) this.activeAssetIndex--
      if (this.activeAssetIndex === 0) prevBtn.hide()
    },
    handleInit(event, slick) {
      slick.$prevArrow.hide()
      slick.$prevArrow.click(() => this.prev(slick.$nextArrow, slick.$prevArrow))
      slick.$nextArrow.click(() => this.next(slick.$nextArrow, slick.$prevArrow))
    },
    attachAssetUrl(asset) {
      return URL.createObjectURL(asset)
    },
    initAssetGroupData() {
      this.creatorComment = this.selectedAsset[0] && this.selectedAsset[0].creatorComment
      this.attachAssetS3Url = this.selectedAsset[0] && this.selectedAsset[0].attachmentFile
    },
    openItemDialog(url, image_id) {
      const image = document.getElementById(image_id)
      const imageAspect = image.naturalWidth / image.naturalHeight
      this.previewingImage = url
      this.$refs.itemDialog.show(imageAspect)
    },
  },
  watch: {
    async selectedAsset() {
      await this.$nextTick()
      this.changeAssetHeight()
    },
    creatorComment() {
      this.$emit('change-creator-comment', this.creatorComment)
    },
    additionalAttachAssets() {
      this.$emit('change-asset-group-addition-attach', this.additionalAttachAssets)
    },
    attachAssetS3Url() {
      this.$emit('change-asset-group-saved-attach', this.attachAssetS3Url)
    },
  },
  computed: {
    // set backGround Color for approval status assetGroup
    backgroundColor() {
      switch (this.selectedAsset[0].approvalStatus) {
        case '監修中':
          return '#909399'
        case '要修正':
          return '#ef342f'
        case '承認':
          return 'rgb(91, 184, 93)'
        case '破棄':
          return '#f4f4f5'
        default:
          return 'white'
      }
    },
    // set text Color for approval status assetGroup
    textColor() {
      switch (this.selectedAsset[0].approvalStatus) {
        case '破棄':
          return '#06063096'
        case '監修未提出':
          return 'rgba(6, 6, 48, 0.45)'
        default:
          return 'white'
      }
    },
    viewActiveIndex() {
      if (this.$route.path === '/creative-regist') {
        return this.carouselActiveIndex
      } else {
        return this.activeIndex
      }
    },
    viewSelectedAsset() {
      const filtered = _.filter(this.selectedAsset, r => r.selected.length !== 0)
      const first = _.chain(filtered).first().result('selected').first().value()

      const selectedLength = _.chain(filtered).map('selected').flattenDeep().value().length

      const assets = _.chain(filtered)
        .map(r =>
          _.map(r.selected, r2 =>
            _.assign(r2, {
              labelName: r.name,
            })
          )
        )
        .flattenDeep()
        .value()

      return {
        maxLabel: this.maxLabel,
        labeledAssets: filtered,
        first,
        selectedLength,
        assets,
      }
    },
    activeAsset() {
      return this.selectedAsset[0].assets ? this.selectedAsset[0].assets[this.activeAssetIndex] : undefined
    },
    slickThumbHeight() {
      return (this.assetThumbMaxHeight + 16) / 4
    },
    disableAttachAsset() {
      return this.attachAssetUrls.length === 5
    },
    attachAssetWidth() {
      return this.assetThumbMaxHeight / 4
    },
    attachAssetUrls() {
      return _.chain(this.attachAssetS3Url)
        .map(s3Url => ({
          url: s3Url,
          name: undefined,
          size: undefined,
        }))
        .concat(
          _.map(this.additionalAttachAssets, asset => ({
            url: this.attachAssetUrl(asset),
            name: asset.name,
            size: asset.size,
          }))
        )
        .flattenDeep()
        .compact()
        .value()
    },
  },
}
</script>
