<template>
  <el-row id="tag-area" class="ml-1">
    <template v-if="creativeType === 'Asset'">
      <el-tag type="warning" effect="dark" class="mr-1"> <i class="fas fa-wrench"></i> アセット </el-tag>
    </template>
    <span class="date-label" v-if="$route.path !== '/creative-regist' && $route.path !== '/creative-regist-only'">
      <i class="el-icon-time"></i> 作成日
    </span>
    <span
      class="date createdDate"
      v-if="$route.path !== '/creative-regist' && $route.path !== '/creative-regist-only'"
      >{{ this.createdDate }}</span
    >
    <span class="date-label" v-if="$route.path !== '/creative-regist' && $route.path !== '/creative-regist-only'">
      <i class="el-icon-refresh-right"></i> 更新日
    </span>
    <span
      class="date updatedDate"
      v-if="$route.path !== '/creative-regist' && $route.path !== '/creative-regist-only'"
      >{{ this.updatedDate }}</span
    >

    <template v-if="isOnlyAsset">
      <el-row class="mt-1">
        <el-tag
          type="info"
          class="mr-1"
          v-if="$route.path !== '/creative-regist' && $route.path !== '/creative-regist-only'"
        >
          <i class="fas fa-tag" /> タグ
        </el-tag>

        <template v-if="tags.length !== 0">
          <span v-for="(tag, i) in tags" :key="`tag_${i}`">
            <el-popover v-if="tag.tagTypeName || tag.description" trigger="hover">
              <div v-if="tag.tagTypeName">カテゴリ: {{ tag.tagTypeName }}</div>
              <div v-if="tag.description">説明: {{ tag.description }}</div>
              <div v-if="tag.creativeId || isTagCreativeCreate">付与された場所：クリエイティブ</div>
              <div v-if="tag.textSetId && !tag.creativeId">付与された場所：テキスト</div>
              <div v-if="tag.originIds && tag.originIds.length !== 0 && !tag.creativeId">付与された場所：画像動画</div>
              <div
                slot="reference"
                class="font-bold mr-1 tag"
                :style="{ background: tag.color || '#909399', color: '#FFFFFF' }"
              >
                {{ tag.tagValue }}
              </div>
            </el-popover>

            <div v-else class="font-bold mr-1 tag" :style="{ background: tag.color || '#909399', color: '#FFFFFF' }">
              {{ tag.tagValue }}
            </div>
          </span>
        </template>

        <span v-else>タグなし</span>
      </el-row>
    </template>

    <template v-else>
      <el-tag
        type="info"
        class="mr-1"
        v-if="$route.path !== '/creative-regist' && $route.path !== '/creative-regist-only'"
      >
        <i class="fas fa-tag" /> タグ
      </el-tag>

      <template v-if="tags.length !== 0">
        <span v-for="(tag, i) in tags" :key="`tag_${i}`">
          <el-popover v-if="tag.tagTypeName || tag.description" trigger="hover">
            <div v-if="tag.tagTypeName">カテゴリ: {{ tag.tagTypeName }}</div>
            <div v-if="tag.description">説明: {{ tag.description }}</div>
            <div v-if="tag.creativeId || isTagCreativeCreate">付与された場所：クリエイティブ</div>
            <div v-if="tag.textSetId && !tag.creativeId">付与された場所：テキスト</div>
            <div v-if="tag.originIds && tag.originIds.length !== 0 && !tag.creativeId">付与された場所：画像動画</div>
            <div
              slot="reference"
              class="font-bold mr-1 tag"
              :style="{ background: tag.color || '#909399', color: '#FFFFFF' }"
            >
              {{ tag.tagValue }}
            </div>
          </el-popover>

          <div v-else class="font-bold mr-1 tag" :style="{ background: tag.color || '#909399', color: '#FFFFFF' }">
            {{ tag.tagValue }}
          </div>
        </span>
      </template>

      <span v-else-if="$route.path !== '/creative-regist' && $route.path !== '/creative-regist-only'">タグなし</span>
      <div v-else style="padding-top: 4px">タグなし</div>
    </template>
  </el-row>
</template>

<style>
#tag-area {
  padding-bottom: 0.5rem;
}
</style>

<style scoped>
.p-1 {
  padding: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.bg-gray {
  background-color: #ebeef5;
}

.tag {
  display: inline-block;
  height: 26px;
  padding: 0 8px;
  line-height: 26px;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}

.date-label {
  border: 1px solid #d3d3d3;
  padding: 4px;
  border-radius: 4px;
  background: #f5f5f5;
}

.date {
  margin-right: 20px;
  margin-left: 10px;
  font-size: 12px;
}
</style>

<script>
export default {
  name: 'tag-area',
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    createdAt: {
      type: String,
      default: () => String,
    },
    updatedAt: {
      type: String,
      default: () => String,
    },
    creativeType: {
      type: String,
      default: () => String,
    },
    isOnlyAsset: {
      type: Boolean,
      default: () => false,
    },
    isShowAlertDisabled: {
      type: Boolean,
      default: () => false,
    },
    isTagCreativeCreate: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    createdDate: null,
    updatedDate: null,
  }),

  created() {
    window.TagArea = this
    this.createdDate =
      this.createdAt.substring(0, 4) + '/' + this.createdAt.substring(4, 6) + '/' + this.createdAt.substring(6, 8)
    this.updatedDate =
      this.updatedAt.substring(0, 4) + '/' + this.updatedAt.substring(4, 6) + '/' + this.updatedAt.substring(6, 8)
  },
  watch: {
    createdAt() {
      this.createdDate =
        this.createdAt.substring(0, 4) + '/' + this.createdAt.substring(4, 6) + '/' + this.createdAt.substring(6, 8)
    },
    updatedAt() {
      this.updatedDate =
        this.updatedAt.substring(0, 4) + '/' + this.updatedAt.substring(4, 6) + '/' + this.updatedAt.substring(6, 8)
    },
  },
}
</script>
