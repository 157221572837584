<template>
  <el-dialog
    id="approval-request-dialog"
    title="監修依頼"
    fullscreen
    append-to-body
    width="100%"
    :visible.sync="isShow"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" style="height: 100%; width: 100%" class="scroll-body">
      <div class="" style="padding: 0.5rem 1rem">選択したクリエイティブ</div>
      <el-tabs v-model="activeCrName" type="border-card" class="detail-tab">
        <el-tab-pane label="提出CR一覧" name="creative" :closable="false">
          <normal-creatives
            :selected="modifiedSelected"
            :ad-formats="adFormats"
            :grouped-creatives="groupedCreatives"
            :asset-group-data="assetGroupData"
            :asset-group-attach-files="assetGroupAttachFiles"
            @change-selected-at-tab="obj => $emit('change-selected-at-tab', obj)"
            @change-creator-comment="
              (comment, creativeId, platformId) => changeCreatorComment(comment, creativeId, platformId)
            "
            @change-note="(note, creativeId, platformId) => changeNote(note, creativeId, platformId)"
            @change-reference-url="(urls, creativeId, platformId) => changeReferenceUrl(urls, creativeId, platformId)"
          />
        </el-tab-pane>
        <el-tab-pane label="制作意図記入（ユニーク）" name="comment" :closable="false">
          <uniq-creatives
            ref="uniqCreatives"
            :selected="modifiedSelected"
            :grouped-creatives="groupedCreatives"
            :ad-formats="adFormats"
            :asset-group-data="assetGroupData"
            @change-selected-at-tab="obj => $emit('change-selected-at-tab', obj)"
            @change-asset-group-payload="data => changeAssetGroupData(data)"
            @change-asset-group-addition-attach="(payload, files) => changeAssetGroupAdditionAttachFile(payload, files)"
            @change-creator-comment-text="
              (comment, textSetId, platformId, creativeId) => changeCreativeCommentText(comment, textSetId, platformId)
            "
          />
        </el-tab-pane>
      </el-tabs>

      <el-divider class="m-0" />

      <!-- Before footer -->
      <el-row class="scroll" id="approvalDetail">
        <el-form class="ps-1 bd-black" @submit.native.prevent="() => null">
          <el-row>
            <el-col :span="13" style="padding-right: 100px; margin-right: 10%; margin-left: 10px">
              <el-row>
                <el-form-item label="監修依頼名 ※デフォルトで「日付_媒体_顧客承認依頼作成者」が挿入されます">
                  <el-input v-model="form.requestName" />
                </el-form-item>
              </el-row>

              <el-row :gutter="50">
                <el-col :span="7">
                  <el-form-item class="field-approval-request" label="対応期日">
                    <el-tag class="required-tag">
                      <span> 必須 </span>
                    </el-tag>
                    <el-date-picker
                      v-model="form.approvalDueDate"
                      type="date"
                      placeholder="対応期日"
                      @change="checkApprovalDueDate"
                      :picker-options="datePickerDueDateOptions"
                      :editable="false"
                    >
                    </el-date-picker>
                    <p class="error-noti" :style="{ display: isApprovalDueDate ? 'block' : 'none' }">
                      対応期日を選択してください
                    </p>
                  </el-form-item>
                </el-col>

                <el-col :span="7">
                  <el-form-item label="対応期日通知" class="field-approval-request">
                    <el-select class="notify-select" v-model="form.approvalNotify" placeholder="対応期日通知" clearable>
                      <el-option
                        v-for="notifyOption in approvalRequestNotifyOptions"
                        :key="`create_ar_notify_option_${notifyOption.text}`"
                        :value="notifyOption.value"
                        :label="notifyOption.text"
                        :style="{ 'text-align': 'center' }"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="7" style="display: flex">
                  <el-form-item label="優先度" class="field-approval-request">
                    <el-tag class="required-tag">
                      <span> 必須 </span>
                    </el-tag>
                    <el-select
                      class="priority-select"
                      v-model="form.approvalPriority"
                      placeholder="優先度"
                      @change="checkApprovalPriority"
                      :style="{
                        color: approvalRequestPriorityOptions[form.approvalPriority]
                          ? approvalRequestPriorityOptions[form.approvalPriority].color
                          : undefined + '!important',
                      }"
                    >
                      <el-option
                        v-for="priorityOption in approvalRequestPriorityOptions.slice().reverse()"
                        :value="priorityOption.value"
                        :label="priorityOption.label"
                        :key="`ar_dialog_priority_${priorityOption.value}`"
                        class="priority-select__option"
                        :style="{
                          color: priorityOption.color,
                          'text-align': 'center',
                        }"
                      ></el-option>
                    </el-select>
                    <p class="error-noti" :style="{ display: isApprovalPriority ? 'block' : 'none' }">
                      優先度を選択してください
                    </p>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="7">
              <el-form-item label="通知対象のDirector" class="field-approval-request form-title">
                <div class="label-danger sub-field-approval-request">
                  <label> ※顧客のメールアドレスは選択できません </label>
                </div>
                <el-form-item label="検索" class="field-approval-request" style="margin-bottom: 20px">
                  <el-select
                    v-model="form.cc"
                    class="priority-select cc-field"
                    v-on:change="addEmailToBox($event, 'cc')"
                    filterable
                    style="display: flex"
                  >
                    <el-option v-for="(r, i) in employees" :key="i" :label="r.name" :value="r.id" />
                  </el-select>
                </el-form-item>
                <el-form-item label="手動入力" class="field-approval-request">
                  <el-input-tag
                    placeholder="追加したいEmailを入力してください"
                    action="approval-request"
                    :arrayCondition="employees.map(e => e.id)"
                    v-model="optionEmail.cc"
                    v-on:input="validateMail($event, 'cc')"
                  />
                </el-form-item>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </div>

    <div slot="footer" class="dialog-footer ar-dialog__footer">
      <el-button @click="isShow = false"> キャンセル </el-button>
      <div>
        <el-button :disabled="isInvalidEmailCc || this.loading" @click="() => approvalRequest('Draft')">
          一時保存
        </el-button>
        <el-button
          type="primary"
          :disabled="isInvalidEmailCc || this.loading"
          @click="() => approvalRequest('Official')"
        >
          監修依頼
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped>
.el-input-tag {
  min-height: 110px;
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.pb-1 {
  padding-bottom: 1rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.ps-1 {
  padding: 0 1rem;
}
.text-center {
  text-align: center;
}
.h-100 {
  height: 100%;
}
</style>

<style scoped>
.dialog-footer.ar-dialog__footer {
  display: flex;
  justify-content: space-between;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.label-danger {
  font-size: small;
}
</style>

<style>
#approval-request-dialog .el-dialog {
  border-radius: 0;
}

#approval-request-dialog .el-tabs__content {
  min-height: 37vh;
}
#approval-request-dialog .el-loading-mask {
  position: fixed;
}
#approval-request-dialog .priority-select .el-input__inner::placeholder,
#approval-request-dialog .notify-select .el-input__inner::placeholder {
  text-align: left;
}

.el-date-table .el-date-table__row .next-month {
  color: #606266 !important;
}
#approval-request-dialog .priority-select .el-input__inner,
#approval-request-dialog .notify-select .el-input__inner {
  color: inherit;
  text-align: center;
}
#approval-request-dialog .cc-field .el-input__inner {
  color: inherit;
  text-align: left;
}
#approval-request-dialog .error-noti {
  color: red;
  margin: 0px;
  font-size: 12px;
}
#approval-request-dialog .detail-tab {
  margin: 0 16px;
}
#approval-request-dialog .el-date-editor--date {
  width: 9vw;
}
#approval-request-dialog .notify-select {
  width: 8vw;
}

#approval-request-dialog .priority-select .el-input--suffix {
  width: 7vw;
}
#approval-request-dialog .el-tabs__item,
#normal-creatives .el-tabs__item {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}
bd-black {
  border: 1px solid #212529;
}
#approval-request-dialog .detail-tab > .el-tabs__content,
#normal-creatives .detail-tab > .el-tabs__content {
  padding: 0;
}
.form-title > label:first-child {
  display: contents !important;
}

.field-approval-request .required-tag {
  align-items: center !important;
  justify-content: center !important;
  height: 17px !important;
  width: 40px !important;
  background-color: #f56c6c !important;
  color: #fff !important;
  font-size: 12px !important;
  border-radius: 5px !important;
  padding-left: 9px !important;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%) !important;
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%) !important;
  line-height: 17px !important;
}

#approval-request-dialog .el-tabs--border-card > .el-tabs__header .el-tabs__item,
#normal-creatives .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #060630;
}

#approval-request-dialog .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active,
#normal-creatives .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #409eff;
}
#approval-request-dialog .cc-field .el-input--suffix {
  width: 100% !important;
}

.label-danger label {
  color: red;
  /* font-size: smaller; */
}
.sub-field-approval-request {
  position: relative;
  top: -20px;
  left: 0px;
  height: 25px;
  /* width: 0px; */
}
.sub-field-approval-request .el-form-item__label .el-form-item__content {
  display: none;
  /* height: 40px; */
  /* margin-top: 0px; */
}
.sub-field-approval-request label {
  font-size: smaller;
}
</style>

<script>
import _, { uniqBy } from 'lodash'
import moment from 'moment'
import NormalCreatives from '@/components/approval-request-dialog/components/normal-creatives'
import UniqCreatives from '@/components/approval-request-dialog/components/uniq-creatives'
import util from '@/mixins/util'
import axios from 'axios'
import { CreativeStatus } from '@/mixins/creativeStatus'
import { AssetStatus } from '@/mixins/assetStatus'
import { TextSetStatus } from '@/mixins/textSetStatus'

import { ElInputTag } from '@/views/creative-list/components/el-input-tag/src'

export default {
  name: 'approval-request-dialog',
  components: { NormalCreatives, UniqCreatives, ElInputTag },
  mixins: [util],
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    approvalRequestDraftData: { type: Object, default: () => {} },
    type: { type: String, default: () => 'default' },
  },
  data: () => ({
    isShow: false,
    loading: false,
    activeCrName: 'comment',
    innerHeight: window.innerHeight,
    displayType: '',
    destination: {},
    isApprovalDueDate: false,
    isApprovalPriority: false,
    form: {
      requestName: '',
      note: '',
      approvalPriority: '',
      approvalNotify: '',
      approvalDueDate: null,
      cc: '',
    },
    assetGroupData: [],
    assetGroupAttachFiles: [],
    datePickerDueDateOptions: {
      disabledDate(time) {
        return time.getTime() < Date.now() - 86400000
      },
    },
    modifiedSelected: [],
    groupedCreatives: [],
    employees: [],
    optionEmail: { cc: '' },
    isInvalidEmailCc: false,
  }),
  async created() {
    window.ApprovalRequestDialog = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })

    this.employees = _.map(await this.$gAuth.getEmployees(), r => ({ id: r.email, name: r.name }))
  },
  methods: {
    async show(displayType) {
      this.isShow = true
      this.loading = true
      this.activeCrName = 'comment'
      const userName = await this.$gAuth.getName()
      const platformIdsStr = _.chain(this.selected).map('platformId').uniq().join('-').value()
      const approvalRequestName = `${moment().format('YYYYMMDD')}_${platformIdsStr}_${userName}`
      this.optionEmail.cc = ''
      this.form = { requestName: approvalRequestName, note: '' }
      if (this.type === 'draft') {
        const approvalRequestName = this.approvalRequestDraftData.approvalRequestName
        const priority = this.approvalRequestDraftData.priority
        const notiDate = this.approvalRequestDraftData.notiDate
          ? this.diffDate(this.approvalRequestDraftData.dueDate, this.approvalRequestDraftData.notiDate)
          : null
        const isDueDate = new Date(this.formatDate(this.approvalRequestDraftData.dueDate)) < new Date()
        const dueDate = isDueDate ? undefined : this.formatDateTime(this.approvalRequestDraftData.dueDate)
        this.form = _.assign(
          {},
          this.form,
          { approvalPriority: priority },
          { approvalDueDate: dueDate },
          { approvalNotify: notiDate },
          { requestName: approvalRequestName }
        )
        this.optionEmail.cc = this.approvalRequestDraftData.ccUser
          ? this.approvalRequestDraftData.ccUser.split(',') || []
          : []
      }

      this.groupedCreatives = this.items
      this.displayType = displayType
      await this.createAssetGroup()
      this.loading = false
    },
    async putAssetGroup() {
      this.loading = true
      const payload = this.preparePayload(this.assetGroupData)
      if (this.assetGroupAttachFiles.length > 0) {
        let formData = new FormData()
        this.assetGroupAttachFiles.forEach(file => formData.append('file', file))
        formData.append('payload', JSON.stringify(payload))

        await axios
          .request({
            method: 'post',
            url: `${this.$api.ctxDomain}/asset_group/upload`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .catch(err => {
            this.$message.error(err)
          })
      } else await this.submitAssetGroupComment()
    },
    addEmailToBox(event, type) {
      this.optionEmail[type] = _.chain(this.optionEmail[type])
        .concat(this.form[type])
        .uniq()
        .value()
        .filter(el => el)
      this.form[type] = null
    },
    validateMail(event, type) {
      this.isInvalidEmailCc = false
      const validateEmail = email => {
        return this.employees.some(e => e.id.includes(String(email).toLowerCase()))
      }
      event.map((v, i) => (event[i] = v.replace(/\s+/g, '')))

      event.map(v => {
        if (!validateEmail(v) && type === 'cc') {
          this.isInvalidEmailCc = true
        }
      })
    },

    async submitAssetGroupComment() {
      if (this.assetGroupData.length > 0) {
        await this.$api.authFetch(`/asset_group`, {
          method: 'POST',
          body: this.assetGroupData,
        })
      }
    },
    changeCreativeCommentText(creatorComment, textSetId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 => {
            if (!r2.textSet || r2.textSet.textSetId !== textSetId) return r2

            const textSet = _.assign({}, r2.textSet, { creatorComment })
            return _.assign({}, r2, { textSet })
          })
          return _.assign({}, r, { creatives })
        })
        .value()
      this.modifiedSelected = _.chain(this.modifiedSelected)
        .cloneDeep()
        .map(r2 => {
          if (!r2.textSet || r2.textSet.textSetId !== textSetId) return r2
          const textSet = _.assign({}, r2.textSet, { creatorComment })
          return _.assign({}, r2, { textSet })
        })
        .value()
    },

    changeCreatorComment(creatorComment, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { creatorComment })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.modifiedSelected = _.chain(this.modifiedSelected)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { creatorComment })))
          .value()
      }
    },
    changeNote(note, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .map(crs => {
          if (crs.platformId !== platformId) return crs

          const creatives = _.map(crs.creatives, creative => {
            if (creative.creativeId !== creativeId) return creative
            return _.assign({}, creative, { note })
          })
          return _.assign({}, crs, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.modifiedSelected = _.chain(this.modifiedSelected)
          .map(cr => {
            if (cr.creativeId !== creativeId) return cr
            return _.assign({}, cr, { note })
          })
          .value()
      }
    },
    changeReferenceUrl(referenceUrls, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { referenceUrls: referenceUrls })
          )
          return _.assign({}, r, { creatives })
        })
        .value()
      this.modifiedSelected = _.chain(this.modifiedSelected)
        .cloneDeep()
        .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { referenceUrls: referenceUrls })))
        .value()
    },
    async putCreatorCommentAssets() {
      const target =
        this.$route.path === '/image-movie-master'
          ? _.uniqBy(this.selected, 'originId')
          : _.chain(this.selected)
              .map('labeledAssets')
              .flattenDeep()
              .map('assets')
              .flattenDeep()
              .uniqBy('originId')
              .value()

      this.loading = true
      const ret = await Promise.all(
        _.map(target, ({ originId, creatorComment }) =>
          this.$api.authFetch('/asset/creator_comment', {
            method: 'POST',
            body: { originId, creatorComment },
          })
        )
      ).finally(() => {
        this.loading = false
      })

      const result = _.filter(ret, r => !_.isEmpty(r))
      if (result.length !== 0) {
        this.$message.error('コメント登録失敗')
        return true
      } else {
        // this.$message.success('コメント登録成功')
        this.$emit('get-assets')
      }
    },
    async putCreatorCommentTextSets() {
      const target =
        this.$route.path === '/text-master'
          ? _.uniqBy(this.modifiedSelected, 'textSetId')
          : _.chain(this.modifiedSelected)
              .map(r => _.assign({}, r.textSet, { adFormatId: r.adFormatId }))
              .uniqBy('textSetId')
              .filter(r => r.textSetId !== undefined)
              .value()

      this.loading = true
      const ret = await Promise.all(
        _.map(target, async textSet => {
          const labeledTexts = _.map(textSet.labeledTexts, r2 => _.pick(r2, ['labelId', 'textValues']))
          const body = {
            textSetId: _.result(textSet, 'textSetId') || '',
            adFormatId: _.result(textSet, 'adFormatId') || '',
            labeledTexts,
            projectName: _.result(textSet, 'projectName') || '',
            score: _.result(textSet, 'score') || 0,
            creatorComment: _.result(textSet, 'creatorComment') || '',
          }

          return this.$api.authFetch('/text_set', { method: 'PUT', body })
        })
      ).finally(() => {
        this.loading = false
      })

      const result = _.filter(ret, r => !r.textSetId)
      if (result.length !== 0) {
        this.$message.error('コメント登録失敗')
        return true
      } else {
        // this.$message.success('コメント登録成功')
        this.$emit('get-text-sets')
      }
    },
    async putCreatorCommentCreatives() {
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds[0]
        : this.$route.query.promotionIds
      this.loading = true
      const ret = await Promise.all(
        _.map(this.modifiedSelected, async creative => {
          const labeledAssets = _.map(creative.labeledAssets, r2 => ({
            labelId: r2.labelId,
            originIds: _.map(r2.assets, 'originId'),
          }))
          const creatorComment = _.result(creative, 'creatorComment') || null
          const creatives = [
            {
              creativeId: _.result(creative, 'creativeId') || '',
              creativeType: _.result(creative, 'creativeType') || '',
              adFormatId: _.result(creative, 'adFormat.id') || '',
              textSetId: _.result(creative, 'textSet.textSetId') || '',
              labeledAssets,
              score: _.result(creative, 'score') || null,
              deviationScore: _.result(creative, 'deviationScore') || null,
              deviationRank: _.result(creative, 'deviationRank') || null,
              note: _.result(creative, 'note'),
              creatorComment: creative.submissionStatus === '入稿済' ? creatorComment + 'submitted' : creatorComment,
              referenceUrls: _.result(creative, 'referenceUrls') || [],
            },
          ]

          this.loading = true
          return this.$api.authFetch('/creative', {
            method: 'PUT',
            body: { promotionId: urlPromotionId, creatives },
          })
        })
      ).finally(() => {
        this.loading = false
      })

      const result = _.filter(ret, r => !r.groupedCreatives)
      if (result.length !== 0) {
        this.$message.error('コメント登録失敗')
        return true
      } else {
        // this.$message.success('コメント登録成功')
        this.$emit('get-creatives')
      }
    },
    putCreatorComment() {
      return this.putCreatorCommentAssets()
        .then(this.putCreatorCommentTextSets())
        .then(this.putCreatorCommentCreatives())
    },

    checkApprovalDueDate() {
      const approvalDueDate = this.form.approvalDueDate === undefined || this.form.approvalDueDate == null
      if (approvalDueDate) {
        this.isApprovalDueDate = true
      } else this.isApprovalDueDate = false
    },
    checkApprovalPriority() {
      const approvalPriority = this.form.approvalPriority === undefined
      if (approvalPriority) {
        this.isApprovalPriority = true
      } else this.isApprovalPriority = false
    },
    scrollTo() {
      const approvalDetail = document.getElementById('approvalDetail')
      if (approvalDetail) {
        approvalDetail.scrollIntoView({ behavior: 'smooth' })
      }
    },
    putForm() {
      const approvalDueDate = this.form.approvalDueDate === undefined || this.form.approvalDueDate == null
      const approvalPriority = this.form.approvalPriority === undefined
      if (approvalDueDate && approvalPriority) {
        this.isApprovalDueDate = true
        this.isApprovalPriority = true
        this.$message.error('対応期日と優先度を選択してください。')
        this.scrollTo()
        return false
      } else if (approvalDueDate) {
        this.isApprovalDueDate = true
        this.$message.error('対応期日を選択してください')
        this.scrollTo()
        return false
      } else if (approvalPriority) {
        this.isApprovalPriority = true
        this.$message.error('優先度を選択してください')
        this.scrollTo()
        return false
      } else return true
    },
    async approvalRequest(approvalCategory) {
      const isValidForm = this.putForm()
      if (isValidForm) {
        this.loading = true
        const ret1 = await this.putCreatorCommentCreatives()
        if (ret1) return
        const ret2 = await this.putCreatorCommentTextSets()
        if (ret2) return
        await this.putAssetGroup()
        if (this.type !== 'draft') {
          const userName = await this.$gAuth.getName()
          const platformIdsStr = _.chain(this.selected).map('platformId').uniq().join('-').value()
          const approvalRequestName = this.form.requestName
            ? `${this.form.requestName}`
            : `${moment().format('YYYYMMDD')}_${platformIdsStr}_${userName}`

          const body = {
            approvalRequestName,
            targetIds: _.map(this.selected, this.targetId),
            displayType: this.displayType,
            promotionId: this.selectedPromotionIds[0],
            promotionName: this.$root.$children[0].selectedPromotions[0].name,
            executionUserId: await this.$gAuth.getEmail(),
            executionUserName: await this.$gAuth.getName(),
            note: this.form.note,
            approvalCategory,
            priority: this.form.approvalPriority,
            dueDate: this.formatDateTime(this.form.approvalDueDate),
            notiDate:
              this.form.approvalNotify != undefined
                ? typeof this.form.approvalNotify == 'number'
                  ? this.form.approvalNotify
                  : -1
                : -1,
            ccUser: this.optionEmail.cc.toString(),
          }

          const { url = '' } =
            (await this.$api.authFetch('/approval_request', { method: 'POST', body }).then(link => {
              this.loading = false
              return link
            })) || {}
          // The operation is performed on the AR creation screen.
          if (approvalCategory != 'Draft') {
            await this.updateCreativeStatus()
          }
          this.loading = false
          this.$emit('update-list-creative', this.modifiedSelected, this.groupedCreatives)
          if (!url) return
          this.isShow = false
          this.$emit('reload')
          if (approvalCategory != 'Draft') {
            this.$emit('open-complete-dialog', url, '監修依頼')
          } else {
            this.$message.success('監修依頼が一時保存されました。')
          }
        }
        // The operation is performed on the AR list screen.
        else {
          const body = {
            id: this.approvalRequestDraftData.approvalRequestId,
            name: this.form.requestName,
            promotionId: this.selectedPromotionIds[0],
            priority: this.form.approvalPriority,
            event: approvalCategory !== 'Draft' ? 'CREATE_AR' : 'UPDATE_AR',
            ownerEmail:
              this.approvalRequestDraftData && this.approvalRequestDraftData.ownerEmail
                ? this.approvalRequestDraftData.ownerEmail
                : await this.$gAuth.getEmail(),
            dueDate:
              this.formatDateTime(this.form.approvalDueDate) == 'Invalid date'
                ? this.form.approvalDueDate
                : this.formatDateTime(this.form.approvalDueDate),
            notiDate:
              this.form.approvalNotify != undefined
                ? typeof this.form.approvalNotify == 'number'
                  ? this.form.approvalNotify
                  : -1
                : -1,
            approvalCategory: approvalCategory,
            ccUser: this.optionEmail.cc.toString(),
          }
          await this.updateCreativeStatus()
          const res = await this.$api.authFetch('/approval_request', {
            method: 'PUT',
            body: {
              ...body,
            },
          })
          if (!res.error) {
            this.loading = false
            this.isShow = false
            if (approvalCategory !== 'Draft') {
              this.$message.success('承認依頼が提出されました。')
              const domain = this.$api.getSepBaseDomain()
              const url = domain
                ? `${domain}/${this.selectedPromotionIds[0]}/approval-requests/${this.approvalRequestDraftData.approvalRequestId}`
                : ''
              this.$emit('open-complete-dialog', url, '監修依頼')
            } else {
              this.$message.success('監修依頼が一時保存されました。')
            }
            this.$emit('reload-data', approvalCategory)
          } else return
        }
      }
    },
    async updateCreativeStatus() {
      const assetIdsPayload = []
      const textSetIdsPayload = []
      const creativeIdsPayload = []
      const creatives = _.chain(this.modifiedSelected)
        .cloneDeep()
        .filter(creative => !CreativeStatus.NOT_UPDATABLE_STATUSES_WHILE_CREATE_AR.includes(creative.approvalStatus))
        .value()

      creatives.forEach(cr => {
        //handle creative status
        creativeIdsPayload.push({ targetId: cr.creativeId })

        //handle asset status
        cr.labeledAssets
          .flatMap(labeledAsset =>
            labeledAsset.assets.filter(asset =>
              this.checkingStatusIsAllow(asset.approvalStatus, AssetStatus.UPDATABLE_STATUSES_WHILE_CREATE_AR)
            )
          )
          .map(asset => assetIdsPayload.push({ targetId: asset.assetId }))

        // handle text status
        if (
          cr.textSet &&
          this.checkingStatusIsAllow(cr.textSet.approvalStatus, TextSetStatus.UPDATABLE_STATUSES_WHILE_CREATE_AR)
        ) {
          textSetIdsPayload.push({ targetId: cr.textSet.textSetId })
        }
      })

      if (creativeIdsPayload.length > 0) {
        // update asset and textSet status before creative status
        await Promise.allSettled([
          this.updateAssetStatus(uniqBy(assetIdsPayload, 'targetId'), AssetStatus.CUSTOMER_CHECKING),
          this.updateTextSetStatus(uniqBy(textSetIdsPayload, 'targetId'), TextSetStatus.CUSTOMER_CHECKING),
        ])
        await this.$api.authFetch('/approval_status', {
          method: 'PUT',
          body: {
            targetType: 'creative',
            targetIds: creativeIdsPayload,
            approvalStatus: this.convertApprovalStatusOptionsJapaneseToEnglish(CreativeStatus.CHECKING),
          },
        })
      }
    },
    async updateAssetStatus(assetIdsPayload, assetStatus) {
      if (assetIdsPayload.length > 0) {
        await this.$api.authFetch('/approval_status', {
          method: 'PUT',
          body: {
            targetType: 'asset',
            targetIds: assetIdsPayload,
            approvalStatus: assetStatus,
          },
        })
      }
    },
    async updateTextSetStatus(textSetIdsPayload, textSetStatus) {
      if (textSetIdsPayload.length > 0) {
        await this.$api.authFetch('/approval_status', {
          method: 'PUT',
          body: {
            targetType: 'textSet',
            targetIds: textSetIdsPayload,
            approvalStatus: textSetStatus,
          },
        })
      }
    },
    checkingStatusIsAllow(updateStatus, listAllowedStatus) {
      for (const allowedStatus of listAllowedStatus) {
        if (updateStatus.trim() === allowedStatus.trim()) {
          return true
        }
      }
      return false
    },
    formatDate(date) {
      return date ? moment(date).format('YYYY-MM-DD') : ''
    },
    changeAssetGroupData(data) {
      if (_.isEmpty(data)) return
      this.assetGroupData = data
      this.modifiedSelected = this.modifySelectedData(this.modifiedSelected, data, this.assetGroupAttachFiles)
    },
    changeAssetGroupAdditionAttachFile(payload, files) {
      if (_.isEmpty(payload)) return
      this.assetGroupAttachFiles = _.union(files, this.assetGroupAttachFiles || [])
      this.changeAssetGroupData(payload)
    },
    checkValidNotifyDate(dueDate, diff) {
      if (dueDate.diff(moment(), 'days') >= diff) {
        this.form.approvalNotiDate = dueDate.subtract(diff, 'days').calendar()
        return true
      } else {
        this.$message.error('Invalid notify date')
        return false
      }
    },
    getAssetGroupData(ids) {
      return this.$api
        .authFetch('/asset_group/list', {
          method: 'POST',
          body: ids,
        })
        .then(res => res)
        .catch(err => {
          console.log(err)
        })
    },
    getAssetGroupId(assetGroup) {
      return _.chain(assetGroup.assets)
        .map(asset => asset.assetId)
        .join('_')
        .value()
    },
    diffDate(date1, date2) {
      return moment(date1).diff(moment(date2), 'days')
    },
    async createAssetGroup() {
      const assetGroupIds = _.chain(this.selected)
        .map(creative => creative.labeledAssets)
        .flattenDeep()
        .map(assetGroup => this.getAssetGroupId(assetGroup)) // get ids
        .uniq() // uniq id
        .value()
      const assetGroupDatas = assetGroupIds.length > 0 ? await this.getAssetGroupData(assetGroupIds) : [] // get data of the asset groups

      this.assetGroupData = _.chain(assetGroupIds)
        .map(
          id =>
            _.find(assetGroupDatas, data => data.assetGroupId === id) || {
              assetGroupId: id,
              creatorComment: '',
              attachmentFile: [],
              additionFile: [],
            }
        )
        .value() // create assetGroupData

      this.modifiedSelected = _.sortBy(this.modifySelectedData(this.selected, assetGroupDatas), [
        c => c.platformId.trim().toLowerCase(),
        c => c.adFormat.name.trim().toLowerCase(),
      ])
    },
    modifySelectedData(selected, assetGroupDatas, additionalFiles = []) {
      return _.chain(selected)
        .map(creative => {
          const labeledAssets = _.map(creative.labeledAssets, assets => {
            const assetGroupId = this.getAssetGroupId(assets)
            const assetGroupData = _.find(assetGroupDatas, data => data.assetGroupId === assetGroupId) || {
              assetGroupId: assetGroupId,
              creatorComment: '',
              attachmentFile: [],
            }
            if (additionalFiles.length > 0) {
              // TODO mayby
              const additionalFileUrls = _.chain(assetGroupData.additionFile)
                .map(fileName => {
                  const file = _.find(additionalFiles, file => file.name === fileName)
                  return file && URL.createObjectURL(file)
                })
                .compact()
                .value()
              assetGroupData.attachmentFile = _.concat(
                this.removeBlobUrl(assetGroupData.attachmentFile || []) || [],
                additionalFileUrls
              )
            } else {
              assetGroupData.attachmentFile = this.removeBlobUrl(assetGroupData.attachmentFile || [])
            } // convert additional files url
            assets.assetGroup = assetGroupData
            return assets
          })
          creative.labeledAssets = labeledAssets
          return creative
        })
        .value() // modify selected data
    },
    removeBlobUrl(urls) {
      return _.filter(urls, url => new URL(url).protocol !== 'blob:')
    },
    removeUnusedAddtionFile() {
      const attachmentFileNames = _.chain(this.assetGroupData)
        .map(assetGroup => assetGroup.additionFile)
        .flattenDeep()
        .uniq()
        .compact()
        .value()
      const compactAttachFiles = _.chain(this.assetGroupAttachFiles)
        .filter(file => _.includes(attachmentFileNames, file.name))
        .uniqBy('name')
        .value()
      this.assetGroupAttachFiles = compactAttachFiles
    },
    preparePayload(payloadRaw) {
      this.removeUnusedAddtionFile()
      let payload = _.map(payloadRaw, assetGroup => {
        return {
          attachmentFile: this.removeBlobUrl(assetGroup.attachmentFile || []),
          assetGroupId: assetGroup.assetGroupId,
          additionFile: assetGroup.additionFile || [],
          creatorComment: assetGroup.creatorComment || '',
        }
      })
      return payload
    },
    formatDateTime(date) {
      return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss.sssZ') : ''
    },
  },
  computed: {
    targetId() {
      if (this.displayType === 'textSet') return 'textSetId'
      else if (this.displayType === 'asset') return 'originId'
      else return 'creativeId'
    },
    height() {
      return this.innerHeight - 35 - 70
    },
    excelApiUrl() {
      return `https://api.hinerankai.net/approval/export_excel${{ dev: '/stg', prod: '' }[this.$api.stage]}`
    },
  },
  watch: {
    items() {
      this.groupedCreatives = this.items
    },
  },
}
</script>
